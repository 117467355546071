document_title = document.title;
$(function () {
   var validFiles = [];
  //Admin panel user sorting
  $('.usersort').on('change', function() {
    window.location = SP_source() + 'admin/users?sort=' + this.value;
  });

  $('.userfriendsort').on('change', function() {
    window.location = Current_url() + '?sort=' + this.value;
  });

  //Admin panel page sorting
  $('.pagesort').on('change', function() {
    window.location = SP_source() + 'admin/pages?sort=' + this.value;
  });

  //Admin panel group sorting
  $('.groupsort').on('change', function() {
    window.location = SP_source() + 'admin/groups?sort=' + this.value;
  });

  //Admin panel event sorting
  $('.eventsort').on('change', function() {
    window.location = SP_source() + 'admin/events?sort=' + this.value;
  });

  $(".form_datetime").datetimepicker({
    format: "mm/dd/yyyy hh:ii"
  });

  $('#link_other a').attr('target', '_blank');
    // $.fn.isolatedScroll = function() {
    //     this.bind('mousewheel DOMMouseScroll', function (e) {
    //         var delta = e.wheelDelta || (e.originalEvent && e.originalEvent.wheelDelta) || -e.detail,
    //             bottomOverflow = this.scrollTop + $(this).outerHeight() - this.scrollHeight >= 0,
    //             topOverflow = this.scrollTop <= 0;

    //         if ((delta < 0 && bottomOverflow) || (delta > 0 && topOverflow)) {
    //             e.preventDefault();
    //         }
    //     });
    //     return this;
    // };

    $('body').on('click','.submit-cover',function(e){
        e.preventDefault();
        $.post(SP_source() + 'ajax/submit-cover',{image_info: $(this).data('image-info'), timeline_id: $(this).data('timeline-id')}, function(responseText) {
            if(responseText.status == 200) {
              location.reload();
            }
        }).fail(function() {
            location.reload();
        });
    });
    $('body').on('click','.submit-avatar',function(e){
        e.preventDefault();
        $.post(SP_source() + 'ajax/submit-avatar',{image_info: $(this).data('image-info'), timeline_id: $(this).data('timeline-id')}, function(responseText) {
            if(responseText.status == 200) {
              location.reload();
            }
        }).fail(function() {
            location.reload();
        });
    });

      $('.post-comment').on('change keyup input',function(){
        textAreaAdjust(this);
      });
//Inviting members to like page

      $('.invite-page-like').on('keyup',function(){
        pageId = $(this).data('page-id');
        if($(this).val() != null && $('this').val() != "")
        $.post( SP_source() + 'ajax/get-members-like' , { searchname: $(this).val() ,page_id: pageId, csrf_token: $('[name="csrf_token"]').attr('content') })
          .done(function( responseText ) {

            if(responseText.status == 200)
            {
              $('.page-suggested-users').empty();
              var users_results = responseText.data;

              $.each(users_results, function(key, value) {

                var user = value[0];
                var likeStatus = '';
                var user_id = '';
                var page_id = '';

                if(user.page_likes[0] != null)
                {
                  user_id = user.page_likes[0].pivot.user_id;
                  page_id = user.page_likes[0].pivot.page_id;
                  likeStatus = 'Liked';
                }
                else
                {
                  user_id = user.id;
                  page_id = pageId;
                  likeStatus = 'Invite';
                }


                 if(user.avatar_id != null){
                    avatarSource = user.avatar_url[0].source;

                  }else{
                    avatarSource = "default-"+user.gender+"-avatar.png";
                  }

                  $('.page-suggested-users').append('<div class="holder">' +
                    '<div class="follower side-left side-left-visible">' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<img src="' + SP_source() + 'user/avatar/'+ avatarSource +'" alt="images">' +
                      '</a>' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<span>' + user.name + '</span>' +
                      '</a>' +
                    '</div>' +
                    '<div class="invite-links side-right">' +
                      '<div class="left-col">' +
                        '<a href="#" class="btn btn-to-follow btn-orange-small invite-page-member add" data-user-id="'+user_id+' - '+page_id+'-'+likeStatus+'">' + likeStatus + '</a>' +
                      '</div>' +
                    '</div>' +
                    '<div class="clearfix"></div>'+
                    '</div>');

              });
            }
        });
      });

      var business_page_id = $('#assign_page_role').data('page-id');
      var selectizeUsers1 = $('#assign_page_role').selectize({
           create: true,
           selectOnTab: true,
           valueField: 'id',
           labelField: 'name',
           optgroupField: 'type',
           maxItems : '1',
           searchField: ['name','username','email'],
           optgroups: [
               {value: 'user', label: 'Users'},
           ],

           render: {
               option: function(item, escape) {
                //  get default images
                var item_image = "default-male-avatar.png";

               if (item.avatar_url[0] != null)
               {
                   var photo_content = '<a class="media-left" href="'+ SP_source() + item.type + '/avatar/' + escape(item.avatar_url[0].source) + '">' +
                     '<img src="'+ SP_source() + item.type +'/avatar/' + escape(item.avatar_url[0].source) + '" alt="...">' +
                   '</a>';
               }
               else
               {
                   var photo_content = '<a class="media-left" href="#">' +
                     '<img src="'+ SP_source()  + item.type + '/avatar/' + item_image + '" alt="...">' +
                   '</a>';
               }

             return '<div class="media big-search-dropdown">' + photo_content +
               '<div class="media-body">' +
                 '<h4 class="media-heading page_member_search">' + escape(item.name) + ' </h4>' +
                '</div>' +
             '</div>';

             },
             option_create:function(data){
                  if ($(".page_member_search").length == 0) {
                       return '<div class="media big-search-dropdown">' +
                                  '<div class="media-body">' +
                                      '<h4 class="media-heading">No Results found</h4>' +
                                      '<p></p>' +
                                 '</div>' +
                              '</div>';
                  }
             },

           },
           onChange: function(id)
           {
              $("#page_user_id").val(id);
           },
           load: function(query, callback) {
               if (!query.length) return callback();
               $.ajax({
                   url: SP_source() + 'ajax/search-page-friends',
                   type: 'POST',
                   dataType: 'json',
                   data: {
                       searchname: query,
                       page_id: business_page_id
                   },
                   error: function() {
                       callback();
                   },
                   success: function(res) {
                      callback(res.data);
                   }
               });
           }
       });

      $('#search_chat_friends').selectize({
           create: true,
           selectOnTab: true,
           valueField: 'id',
           labelField: 'name',
           optgroupField: 'type',
           maxItems : '1',
           searchField: ['name','username'],
           optgroups: [
               {value: 'user', label: 'Users'},
           ],

           render: {
               option: function(item, escape) {
                //  get default images
                var item_image = "default-male-avatar.png";

               if (item.avatar_url[0] != null)
               {
                   var photo_content = '<a class="media-left" href="'+ SP_source() + item.type + '/avatar/' + escape(item.avatar_url[0].source) + '">' +
                     '<img src="'+ SP_source() + item.type +'/avatar/' + escape(item.avatar_url[0].source) + '" alt="...">' +
                   '</a>';
               }
               else
               {
                   var photo_content = '<a class="media-left" href="#">' +
                     '<img src="'+ SP_source()  + item.type + '/avatar/' + item_image + '" alt="...">' +
                   '</a>';
               }

             return '<div class="media big-search-dropdown">' + photo_content +
               '<div class="media-body">' +
                 '<h4 class="media-heading page_member_search">' + escape(item.name) + ' </h4>' +
                '</div>' +
             '</div>';

             },
             option_create:function(data){
                  if ($(".page_member_search").length == 0) {
                       return '<div class="media big-search-dropdown">' +
                                  '<div class="media-body">' +
                                      '<h4 class="media-heading">No Results found</h4>' +
                                      '<p></p>' +
                                 '</div>' +
                              '</div>';
                  }
             },

           },
           onChange: function(id)
           {
              $.post(SP_source() + 'ajax/get-friend/' + id , function(response) {
                if(response.status) {
                  chatBoxes.showChatBox(response.data)
                }
              });

              var $select = $('#search_chat_friends').selectize();
              var control = $select[0].selectize;
              control.clear();
           },
           load: function(query, callback) {
               if (!query.length) return callback();
               $.ajax({
                   url: SP_source() + 'ajax/search-chat-friends',
                   type: 'POST',
                   dataType: 'json',
                   data: {
                       searchname: query
                   },
                   error: function() {
                       callback();
                   },
                   success: function(res) {
                      callback(res.data);
                   }
               });
           }
       });

    // $('.page-services, .page-gallery-index, .all-albums, .all-photos, .page-video-index').jscroll({ // load more business-services, page gallery photos
    //   nextSelector: 'a.jscroll-next:last'
    // });


    // show users modal
    $('body').on('click','.show-users-modal',function(e){
        e.preventDefault();
        // $(this).tooltip('hide');

        $.post(SP_source() + 'ajax/get-users-modal',{user_ids: $(this).data('users'), heading: $(this).data('heading')}, function(responseText) {
           if(responseText.status == 200) {
               $('.edit-content').html(responseText.responseHtml);
           }
         }).fail(function() {
            location.reload();
          });

        $('#usersModal').modal('show');
    });

    $('body').on('click','.edit-post',function(e){
        e.preventDefault();
        $.post(SP_source() + 'ajax/edit-post',{post_id: $(this).data('post-id'),}, function(responseText) {
           if(responseText.status == 200) {
               $('.edit-content').html(responseText.data);
           }
        });
        $('#usersModal').modal('show');
        setTimeout(function(){
          jQuery("time.timeago").timeago();
        },100);
    });

    $('body').on('click','.embed-post',function(e){
        e.preventDefault();
        var url = SP_source() + 'share-post/' + $(this).data('post-id')
        $('.embed-content').html('<iframe src="' + url + '" width="600px" height="420px" frameborder="0"></iframe>');
        $('#embedModal').modal('show');
    });

    $('body').on('click','.btn-delete-user',function(){
        if(confirm('are you sure to delete?'))
        {
            window.location = base_url  + current_username + '/settings/deleteme';
        }
    });

    emojify.setConfig({img_dir : theme_url + 'images/emoji/basic'});
    // This will show modal when the settings are saved and flashed with overlay
    $('#flash-overlay-modal').modal();

    $( "#datepick2" ).datepicker();
    jQuery("time.timeago").timeago();

    $('.create-post-form').ajaxForm({
        url: SP_source() + 'ajax/create-post',
        beforeSubmit : function validate(formData, jqForm, options) {
            var form = jqForm[0];
            //Uploading selected images on create post box
            var hasFile = false

            for(var i=0; i<=validFiles.length; i++){
                if(validFiles[i] != null)
                {
                    hasFile = true
                    //var file = new File([validFiles[i]], validFiles[i].name  ,{type: validFiles[i].type});
                    var file = validFiles[i];
                    formData.push({name:'post_images_upload[]', value: file})
                }
            }
            validFiles = []; // making array empty

            if (!hasFile && !$('.post-images-upload').val() && !$('.post-video-upload').val() && !form.description.value && !form.youtube_video_id.value && !form.location.value && !form.soundcloud_id.value) {
                alert("Your post cannot be empty!")
                return false;
            }

        },
        beforeSend: function() {
            create_post_form = $('.create-post-form');
            create_post_button = create_post_form.find('.btn-submit');
            create_post_button.attr('disabled', true).append(' <i class="fa fa-spinner fa-pulse "></i>');
            create_post_form.find('.post-message').fadeOut('fast');
        },

        success: function(responseText) {
          create_post_button.attr('disabled', false).find('.fa-spinner').addClass('hidden');
            if (responseText.status == 200)
            {
              $('.timeline-posts').prepend(responseText.data.original);
              jQuery("time.timeago").timeago();
              $('.no-posts').hide();
              // Resetting the create post form after successfull message
              $('.video-addon').hide();
              $('.music-addon').hide();
              $('.emoticons-wrapper').hide();
              $('.user-tags-addon').hide();
              $('.user-tags-added').hide();
              $(".user-results").hide();
              create_post_form.find("input[type=text], textarea, input[type=file]").val("");
              create_post_form.find('.youtube-iframe').empty();
              create_post_form.find('#post-image-holder').empty();
              create_post_form.find('.post-images-selected').hide();
              create_post_form.find('#post-video-holder').empty();
              create_post_form.find('.post-videos-selected').hide();
              $('[name="youtube_video_id"]').val('');
              $('[name="youtube_title"]').val('');
              $('[name="soundcloud_id"]').val('');
              $('[name="soundcloud_title"]').val('');
              $('[name="user_tags[]"]').val('');
              $('.user-tags').val('');
              $('.user-tag-names').empty('');
              emojify.run();
              hashtagify();
              mentionify();
              $('[data-toggle="tooltip"]').tooltip();
              $('[name="description"]').focus();
              notify('Your post has been successfully published');

            }
            else
            {
                $('.login-errors').html(responseText.message);

            }

        }
    });

    // Toggle youtube input in create post form
    $('#videoUpload').on('click',function(e){
        e.preventDefault();
        $('.video-addon').slideToggle();
        if($(".music-addon").css("display") === "block"){
            $(".music-addon").slideUp(300);
        }
    });

    // Toggle add Tags input in create post form
    $('#addUserTags').on('click',function(e){
        e.preventDefault();
        $('.user-tags-addon').slideToggle();
        $('.user-tags-added').slideToggle();

    });



    // Toggle music input in create post form
    $('#musicUpload').on('click',function(e){
        e.preventDefault();
        $('.music-addon').slideToggle();
        if($(".video-addon").css("display") === "block"){
            $(".video-addon").slideUp(300);
        }
    });
     // Toggle location input in create post form
    $('#locationUpload').on('click',function(e){
        e.preventDefault();
        $('.location-addon').slideToggle();
    });
     // Toggle emoticons input in create post form
    $('#emoticons').on('click',function(e){
        e.preventDefault();
        var emoticonButton = $(this);
        if(!emoticonButton.hasClass('loaded-emoji'))
        {
          $.get( SP_source() + 'ajax/load-emoji')
              .done(function( data ) {
                  $('.emoticons-wrapper').html(data.data);
                  emojify.run();
                  emoticonButton.addClass('loaded-emoji')
          });
        }

        $('.emoticons-wrapper').slideToggle();
    });

    // Fetch users id when keyup
    // $('.user-tags').on('keyup',function(){

    //     if($(".user-results").length){
    //         $(".user-results").show();
    //         $('.user-results').html('<i class="fa fa-spinner fa-pulse"></i> Fetching Sound Cloud results...');
    //     }
    //     else
    //     {
    //         $('.users-results-wrapper').html('<div class="list-group user-results"><i class="fa fa-spinner fa-pulse"></i> Fetching Sound Cloud results...</div>');
    //     }

    //     $.get( SP_source() + 'ajax/get-users-mentions' , { query: $('.user-tags').val() , csrf_token: $('[name="csrf_token"]').attr('content') })
    //         .done(function( data ) {
    //             $('.user-results').html('');
    //             $.each(data, function(key, value) {
    //                 $('.user-results').append('<a class="list-group-item user-result-item" data-user-id="' + value.id  + '" data-user-name="' + value.name  + '" href="#"> <img src="' +  value.image + '"> '+ value.name + '<span></span><div class="clearfix"></div></a>');
    //             });
    //     });
    // });


    // Fetch the youtube title and id when keyup
    $('#youtubeText').on('keyup',function(){
      var video_addon = $(this).closest('.video-addon');
      video_addon.find('.fa-film').addClass('fa-spinner fa-spin');
      $(this).closest('.video-addon').find('.fa-film').addClass('fa-spinner fa-spin');
      $.post( SP_source() + 'ajax/get-youtube-video' , { youtube_source: $('#youtubeText').val() , csrf_token: $('[name="csrf_token"]').attr('content') })
        .done(function( data ) {
          if(data.status == 200)
          {
              $('.youtube-iframe').html(data.message.iframe);
              $('[name="youtube_video_id"]').val(data.message.id);
              $('[name="youtube_title"]').val(data.message.title);
              video_addon.find('.fa-film').removeClass('fa-spinner fa-spin');
          }
      });
    });

    $('#youtubeText').bind('input propertychange', function() {
       var video_addon = $(this).closest('.video-addon');
        video_addon.find('.fa-film').addClass('fa-spinner fa-spin');
        $(this).closest('.video-addon').find('.fa-film').addClass('fa-spinner fa-spin');
        $.post( SP_source() + 'ajax/get-youtube-video' , { youtube_source: $('#youtubeText').val() , csrf_token: $('[name="csrf_token"]').attr('content') })
          .done(function( data ) {
            if(data.status == 200)
            {
                $('.youtube-iframe').html(data.message.iframe);
                $('[name="youtube_video_id"]').val(data.message.id);
                $('[name="youtube_title"]').val(data.message.title);
                video_addon.find('.fa-film').removeClass('fa-spinner fa-spin');
            }
        });
    });

    // Fetch the youtube title and id when keyup
    $('#soundCloudText').on('keyup',function(){
        var music_addon = $(this).closest('.music-addon');
      if($(".soundcloud-results").length){
          $(".soundcloud-results").show();
          music_addon.find('.fa-music').addClass('fa-spinner fa-spin');
          $(this).closest('.music-addon').find('.fa-music').addClass('fa-spinner fa-spin')
      }
      else
      {
        $('.soundcloud-results-wrapper').html('<div class="list-group soundcloud-results"></div>');
      }
      $.post( SP_source() + 'ajax/get-soundcloud-results' , { q: $('#soundCloudText').val() , csrf_token: $('[name="csrf_token"]').attr('content') })
        .done(function( responseText ) {
          if(responseText.status == 200)
          {
            music_addon.find('.fa-music').removeClass('fa-spinner fa-spin');
            $('.soundcloud-results').html('');
            var soundCloud_results = jQuery.parseJSON(responseText.data);
            $.each(soundCloud_results, function(key, value) {
                $('.soundcloud-results').append('<a class="list-group-item soundcloud-result-item" data-soundcloud-id="' + value.id  + '" data-soundcloud-title="' + value.title  + '" href="#"> <img src="' +  value.artwork_url + '"> '+ value.title + '</a>');
            });

          }
      });
    });



    // Like/Unlike the post by user
    $(document).on('click','.soundcloud-result-item',function(e){
      e.preventDefault();
      $('#soundCloudText').val($(this).data('soundcloud-title'));
      $('.soundcloud-results').slideToggle();
      $('[name="soundcloud_id"]').val($(this).data('soundcloud-id'));
      $('[name="soundcloud_title"]').val($(this).data('soundcloud-title'));

    });

    // Add user to the post as tag
    $(document).on('click','.user-result-item',function(e){
      e.preventDefault();
      $('.user-tags-added').append('<input type="hidden" value="' + $(this).data('user-id') + '" name="user_tags[]" >');

        var values = $("input[name='user_tags[]']")
              .map(function(){return $(this).val();}).get();
        if(values.length <= 1)
        {
            $('.user-tags-added').find('.user-tag-names').append('<a href="#">' + $(this).data('user-name')  + '</a>');
        }
        else
        {
            $('.user-tags-added').find('.user-tag-names').append(', <a href="#">' + $(this).data('user-name')  + '</a>');
        }


    });


    // Like/Unlike the post by user
    $(document).on('click','.like-post',function(e){
      e.preventDefault();
      like_btn = $(this).closest('.panel-post');
      postId = $(this).data('post-id');
      $.post(SP_source() + 'ajax/like-post', {post_id: $(this).data('post-id')}, function(data) {
       if (data.status == 200) {
         if (data.liked == true) {
           like_btn.find('.like-'+postId).parent().addClass('hidden');
           like_btn.find('.unlike-'+postId).parent().removeClass('hidden');
           like_btn.find('.notify').parent().addClass('hidden');
           like_btn.find('.unnotify').parent().removeClass('hidden');
           $('.footer-list').find('.like1-'+postId).parent().remove();
           $('.like2-'+postId).empty();
           $('.footer-list').find('.like2-'+postId).removeClass('hidden').append('<a href="#" class=".show-likes">' + data.likecount + '<i class="fa fa-thumbs-up"></i></a>');
           like_btn.find('.like_count').html(data.likecount);

         }else{
           like_btn.find('.like-'+postId).parent().removeClass('hidden');
           like_btn.find('.unlike-'+postId).parent().addClass('hidden');
           like_btn.find('.notify').parent().removeClass('hidden');
           like_btn.find('.unnotify').parent().addClass('hidden');
           $('.footer-list').find('.like1-'+postId).parent().remove();
           $('.like2-'+postId).empty();
           $('.footer-list').find('.like2-'+postId).removeClass('hidden').append('<a href="#" class=".show-likes">' + data.likecount + '<i class="fa fa-thumbs-up"></i></a>');
           like_btn.find('.like_count').html(data.likecount);
         }
        }
      });
    });

    // Join/Joined the timeline user  by  logged user
    $(document).on('click','.join-user',function(e){
      e.preventDefault();
      join_btn = $(this).closest('.join-links');
      $.post(SP_source() + 'ajax/join-group', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.joined == true) {
                 join_btn.find('.join').parent().addClass('hidden');
                 join_btn.find('.joined').parent().removeClass('hidden');
             } else {
               join_btn.find('.join').parent().removeClass('hidden');
               join_btn.find('.joined').parent().addClass('hidden');
             }
         }
     });
    });

    // Join/Joined the event guests  by  logged user
    $('.join-guest').on('click',function(e){
      e.preventDefault();
      join_btn = $(this).closest('.join-links');
      $.post(SP_source() + 'ajax/join-event', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.joined == true) {
                 join_btn.find('.join').parent().addClass('hidden');
                 join_btn.find('.joined').parent().removeClass('hidden');
             } else {
               join_btn.find('.join').parent().removeClass('hidden');
               join_btn.find('.joined').parent().addClass('hidden');
             }
         }
     });
    });

    // Follow/Requested switching by clcking on follow
    $('.follow-user-confirm').on('click',function(e){
      e.preventDefault();
      join_btn = $(this).closest('.follow-links');
      input_ids = $(this).data('timeline-id').split('-');
      timeline_id = input_ids[0];
      follow_status = input_ids[1];
      $.post(SP_source() + 'ajax/follow-user-confirm', {timeline_id: timeline_id, follow_status: follow_status}, function(data) {
         if (data.status == 200) {
             if (data.followrequest == true) {
                 join_btn.find('.follow').parent().addClass('hidden');
                 join_btn.find('.followrequest').parent().removeClass('hidden');
             }
             else if(data.unfollow == true)
             {
               join_btn.find('.unfollow').parent().addClass('hidden');
               join_btn.find('.follow').parent().removeClass('hidden');
             }
             else
             {
               join_btn.find('.follow').parent().removeClass('hidden');
               join_btn.find('.followrequest').parent().addClass('hidden');
             }
         }
     });
    });

    // Join group/Joined the timeline user  by  logged user
    $('.join-close-group').on('click',function(e){
      e.preventDefault();
      join_btn = $(this).closest('.join-links');
      $.post(SP_source() + 'ajax/join-close-group', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
          if (data.joinrequest == true) {
            join_btn.find('.join').parent().addClass('hidden');
            join_btn.find('.joinrequest').parent().removeClass('hidden');
          } else if(data.join == true) {
            join_btn.find('.joined').parent().addClass('hidden');
            join_btn.find('.join').parent().removeClass('hidden');
            location.reload();
          }else{
            join_btn.find('.join').parent().removeClass('hidden');
            join_btn.find('.joinrequest').parent().addClass('hidden');
          }
         }
     });
    });

    // Follow/UnFollow the timeline user  by  logged user
    $('body').on('click','.follow-user',function(e){
        var page_reload = false;
      e.preventDefault();
      follow_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/follow-post', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.followed == true) {
                 if(follow_btn.find('.follow').hasClass('yudu-follow')){
                    page_reload = true;
                 }
                 follow_btn.find('.follow').parent().addClass('hidden');
                 follow_btn.find('.unfollow').parent().removeClass('hidden');
             } else {
               follow_btn.find('.follow').parent().removeClass('hidden');
               follow_btn.find('.unfollow').parent().addClass('hidden');
             }
            if (page_reload) {
               location.reload();
            }
         }
     }).fail(function() {
          location.reload();
        });
    });

    // Friend/UnFriend the timeline user by logged user
    $('body').on('click','.friend-user',function(e){
      e.preventDefault();
      follow_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/friend-post', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.followed == true) {
                 follow_btn.find('.follow').parent().addClass('hidden');
                 follow_btn.find('.unfollow').parent().removeClass('hidden');
             } else {
               follow_btn.find('.follow').parent().removeClass('hidden');
               follow_btn.find('.unfollow').parent().addClass('hidden');
             }
             location.reload();
         }
     }).fail(function() {
          location.reload();
        });
    });

    $('body').on('click',function(e){
        if (!$('#friends_list').is(e.target)
            && $('#friends_list').has(e.target).length === 0
        ) {
            $(".show-friend-requests").attr("data-toggle", "dropdown");
        }
        else{
            $('.show-friend-requests').removeAttr('data-toggle');
        }
    });

    $('body').on('click','.confirm-friend-user',function(e){
        e.preventDefault();
        var parent_div = $(this).closest('.inbox-message');
        var parent_div1 = $(this).closest('.friend-request-wrap');
        $.post(SP_source() + 'ajax/confirm-friend-post', {timeline_id: $(this).data('timeline-id')}, function(data) {
            if (data.status == 200) {
                if (data.friend == true) {
                   parent_div.addClass('hidden');
                   parent_div1.addClass('hidden');
                   updateFriendRequestCount();
                }
            }
        }).fail(function() {
            location.reload();
        });
    });

    $('body').on('click','.reject-friend-user',function(e){
      e.preventDefault();
      var parent_div = $(this).closest('.inbox-message');
      var parent_div1 = $(this).closest('.friend-request-wrap');
      $.post(SP_source() + 'ajax/reject-friend-post', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
            if (data.friend == true) {
                parent_div.addClass('hidden');
                parent_div1.addClass('hidden');
                updateFriendRequestCount();
            }
         }
     }).fail(function() {
          location.reload();
        });
    });

    $('body').on('click','.unfriend-user',function(e){
      e.preventDefault();
      follow_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/unfriend-friend-post', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.followed == true) {
                 follow_btn.find('.follow').parent().addClass('hidden');
                 follow_btn.find('.unfollow').parent().removeClass('hidden');
             } else {
               follow_btn.find('.follow').parent().removeClass('hidden');
               follow_btn.find('.unfollow').parent().addClass('hidden');
             }
             location.reload();
         }
     }).fail(function() {
          location.reload();
        });
    });

    //Accept user request through join request tab in close group
    $('.accept-user').on('click',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      group_id = input_ids[1];

      accept_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/join-accept', {user_id: user_id,group_id: group_id}, function(data) {
       if (data.status == 200) {
         if (data.accepted == true) {
           accept_btn.find('.accept').closest('.holder').slideToggle();
           location.reload();
         }
       }
     });
    });


//Accept follow request through join request tab in close group
    $('.accept-follow').on('click',function(e){
      e.preventDefault();

      accept_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/follow-accept', {user_id: $(this).data('user-id')}, function(data) {
       if (data.status == 200) {
         if (data.accepted == true) {
          accept_btn.find('.accept').closest('.holder').slideToggle();
         }
       }
     });
    });


  //Reject follow user request through join request tab in close group
 $('.reject-follow').on('click',function(e){
      e.preventDefault();

      reject_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/follow-reject', {user_id: $(this).data('user-id')}, function(data) {
       if (data.status == 200) {
         if (data.rejected == true) {
          reject_btn.find('.reject').closest('.holder').slideToggle();
         }
       }
     });
    });





  //Adding follower through add members tab in close group
  $(document).on('click','.add-member',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      group_id = input_ids[1];
      user_status = input_ids[2];

      add_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/add-memberGroup', {user_id: user_id,group_id: group_id,user_status: user_status}, function(data) {
       if (data.status == 200) {
         if (data.added == true) {
           add_btn.find('.add').closest('.holder').slideToggle();
         }
       }
     });
    });

  //Adding follower through add members tab in page
  $(document).on('click','.add-page-member',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      page_id = input_ids[1];
      user_status = input_ids[2];

      add_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/add-page-members', {user_id: user_id,page_id: page_id,user_status: user_status}, function(data) {
       if (data.status == 200) {
         if (data.added == true) {
           add_btn.find('.add').closest('.holder').slideToggle();
         }
       }
     });
    });


  //Adding follower through add members tab in page
  $(document).on('click','.invite-page-member',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      page_id = input_ids[1];
      user_status = input_ids[2];

      add_btn = $(this).closest('.invite-links');
      $.post(SP_source() + 'ajax/invite-page-members', {user_id: user_id,page_id: page_id,user_status: user_status}, function(data) {
       if (data.status == 200) {
         if (data.added == true) {
           add_btn.find('.add').closest('.holder').slideToggle();
         }
       }
     });
    });

  //Adding follower through add members tab in page
  $(document).on('click','.add-event-member',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      event_id = input_ids[1];
      user_status = input_ids[2];

      add_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/add-event-members', {user_id: user_id,event_id: event_id,user_status: user_status}, function(data) {
       if (data.status == 200) {
         if (data.added == true) {
           add_btn.find('.add').closest('.holder').slideToggle();
         }
       }
     });
    });

  //Reject user request through join request tab in close group
    $('.reject-user').on('click',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      group_id = input_ids[1];

      reject_btn = $(this).closest('.follow-links');
      $.post(SP_source() + 'ajax/join-reject', {user_id: user_id,group_id: group_id}, function(data) {
       if (data.status == 200) {
         if (data.rejected == true) {
           reject_btn.find('.reject').closest('.holder').slideToggle();
           location.reload();
         }
       }
     });
    });

    //Manage report user request
    $('.manage-report').on('click',function(e){
      e.preventDefault();
      post_id = $(this).data('post-id');

      report_btn = $(this).closest('.list-inline');
      $.post(SP_source() + 'ajax/report-post', {post_id: post_id}, function(data) {
       if (data.status == 200) {
         if (data.reported == true) {
           //report_btn.find('.report').closest('.holder').slideToggle();
           $('#post'+post_id).slideToggle();
           notify('You have successfully reported the page');
         }
       }
     });
    });

    // smiley's on posts
    $(document).on('click','.smiley-post',function(e){
        e.preventDefault();
        textbox = $("#createPost");
        textbox.val(textbox.val() +' '+$(this).data('smiley-id'));
        textbox.focus();
    });


    // Page Like/Liked the timeline user  by  logged user
    $(document).on('click','.page-like',function(e){
      e.preventDefault();
      pagelike_btn = $(this).closest('.pagelike-links');
      $.post(SP_source() + 'ajax/page-like', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.liked == true) {
                 pagelike_btn.find('.like').parent().addClass('hidden');
                 pagelike_btn.find('.liked').parent().removeClass('hidden');
             } else {
               pagelike_btn.find('.like').parent().removeClass('hidden');
               pagelike_btn.find('.liked').parent().addClass('hidden');
             }
         }
     });
    });

    // Page Report/Reported the timeline user  by  logged user
    $('.page-report').on('click',function(e){
      e.preventDefault();
      pagereport_btn = $(this).closest('.pagelike-links');
      $.post(SP_source() + 'ajax/page-report', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.reported == true) {
                 pagereport_btn.find('.report').parent().addClass('hidden');
                 pagereport_btn.find('.reported').parent().removeClass('hidden');
                 notify('You have successfully reported the page');
             } else {
               pagereport_btn.find('.report').parent().removeClass('hidden');
               pagereport_btn.find('.reported').parent().addClass('hidden');
               notify('You have successfully unreported the page');
             }
         }
     });
    });

    // Block/Unblock the timeline by logged user
    $('.control-access').on('click',function(e){
      e.preventDefault();

      pagereport_btn = $(this).closest('.pagelike-links');
      timeline_type = $(this).data('timeline-type') ? $(this).data('timeline-type') : 'page';
      $timeline_id = $(this).data('timeline-id');

      action_type = $(this).hasClass('blocked') ? 'unblock' : 'block';

      dialog_content = '';
      dialog_content += '<p> Do you want to '+ action_type +' this '+ timeline_type +'? </p>';
      if (action_type == 'block') {
        dialog_content += '<p> This '+ timeline_type +' will no longer be able to: <br>';
        dialog_content +=   '<ul>';
        dialog_content +=       '<li>See things you post on your timeline</li>';
        dialog_content +=       '<li>Tag you</li>';
        dialog_content +=       '<li>Follow you</li>';
        dialog_content +=       '<li>View your pages</li>';
        dialog_content +=       '<li>View or join your Groups</li>';
        dialog_content +=   '</ul>';
        dialog_content += '</p>';
        dialog_content += '<p> Blocking this user will also unfriend him. <p>';
      }

      $.confirm({
        title: 'Confirm!',
        content: dialog_content,
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
            $.post(SP_source() + 'ajax/block-user', {'timeline_id': $timeline_id}, function(data) {
                if (data.status == 200) {
                    if (data.blocked == true) {
                        pagereport_btn.find('.block').parent().addClass('hidden');
                        pagereport_btn.find('.blocked').parent().removeClass('hidden');
                        notify('You have successfully blocked the ' + timeline_type);
                    } else {
                      pagereport_btn.find('.block').parent().removeClass('hidden');
                      pagereport_btn.find('.blocked').parent().addClass('hidden');
                      notify('You have successfully unblocked the ' + timeline_type);
                    }
                    location.reload();
                }
            });
       },
       cancel: function(){

       }
      });
    });

    // Comment Like/Liked the timeline user  by  logged user
    $(document).on('click','.like-comment',function(e){
      e.preventDefault();
      commentId = $(this).data('comment-id');
      commentlike_btn = $(this).closest('.comments-list');
      $.post(SP_source() + 'ajax/comment-like', {comment_id: $(this).data('comment-id')}, function(data) {
         if (data.status == 200) {
             if (data.liked == true) {
               commentlike_btn.find('.like').parent().addClass('hidden');
               commentlike_btn.find('.unlike').parent().removeClass('hidden');
               $('.comments-list').find('.like3-'+commentId).parent().addClass('hidden');
               $('.like4-'+commentId).empty();
               $('.comments-list').find('.like4-'+commentId).removeClass('hidden').append('<a href="#" class=".show-likes">' + data.likecount + '<i class="fa fa-thumbs-up"></i></a>');
             } else {
               commentlike_btn.find('.like').parent().removeClass('hidden');
               commentlike_btn.find('.unlike').parent().addClass('hidden');
               $('.comments-list').find('.like3-'+commentId).parent().addClass('hidden');
               $('.like4-'+commentId).empty();
               $('.comments-list').find('.like4-'+commentId).removeClass('hidden').append('<a href="#" class=".show-likes">' + data.likecount + '<i class="fa fa-thumbs-up"></i></a>');
             }
         }
     });
    });

    //Post Share/shared the timeline user  by  logged user
    $('body').on('click','.submit-share',function(e){
      e.preventDefault();
      post_id = $(this).data('post-id');
      sharepost_btn = $(this).closest('.list-inline');
      formdata = $("#share_post_form").serialize();
      $.post(SP_source() + 'ajax/share-post', formdata
         , function(data) {
         if (data.status == 200) {
             if (data.shared == true) {
                $('.timeline-posts').prepend(data.post.original);
                jQuery("time.timeago").timeago();
                $('.no-posts').hide();
                emojify.run();
                hashtagify();
                mentionify();
                $('[data-toggle="tooltip"]').tooltip();
                $('[name="description"]').focus();
                sharepost_btn.find('.share').parent().addClass('hidden');
                sharepost_btn.find('.shared').parent().removeClass('hidden');
                $('.list-inline').find('.share1-'+post_id).parent().addClass('hidden');
                $('.share2-'+post_id).empty();
                $('.list-inline').find('.share2-'+post_id).removeClass('hidden').append('<a href="#" class=".show-share">' + data.share_count + '<i class="fa fa-share"></i></a>');
                notify('Post has been successfully shared');
                $('#usersModal').modal('hide');
             } else {
                sharepost_btn.find('.share').parent().removeClass('hidden');
                sharepost_btn.find('.shared').parent().addClass('hidden');
                $('.list-inline').find('.share1-'+post_id).parent().addClass('hidden');
                $('.share2-'+post_id).empty();
                $('.list-inline').find('.share2-'+post_id).removeClass('hidden').append('<a href="#" class=".show-share">' + data.share_count + '<i class="fa fa-share"></i></a>');
                location.reload();
                notify('Post has been successfully unshared');
             }
         }
         else{
            if(data.error == true)
                $('.share_errors').html("<div class='login-error-text'>" + data.message + "</div>");
         }
     }).fail(function() {
          location.reload();
        });
    });

    //Post Share/shared the timeline user  by  logged user
    $('body').on('click','.unshare-post',function(e){
      e.preventDefault();
      post_id = $(this).data('post-id');
      sharepost_btn = $(this).closest('.list-inline');
      $.post(SP_source() + 'ajax/unshare-post', {post_id: post_id,
        }, function(data) {
         if (data.status == 200) {
             if (data.shared == true) {
                $('.timeline-posts').prepend(data.post.original);
                jQuery("time.timeago").timeago();
                $('.no-posts').hide();
                emojify.run();
                hashtagify();
                mentionify();
                $('[data-toggle="tooltip"]').tooltip();
                $('[name="description"]').focus();
                sharepost_btn.find('.share').parent().addClass('hidden');
                sharepost_btn.find('.shared').parent().removeClass('hidden');
                $('.list-inline').find('.share1-'+post_id).parent().addClass('hidden');
                $('.share2-'+post_id).empty();
                $('.list-inline').find('.share2-'+post_id).removeClass('hidden').append('<a href="#" class=".show-share">' + data.share_count + '<i class="fa fa-share"></i></a>');
                notify('Post has been successfully shared');
             } else {
                sharepost_btn.find('.share').parent().removeClass('hidden');
                sharepost_btn.find('.shared').parent().addClass('hidden');
                $('.list-inline').find('.share1-'+post_id).parent().addClass('hidden');
                $('.share2-'+post_id).empty();
                $('.list-inline').find('.share2-'+post_id).removeClass('hidden').append('<a href="#" class=".show-share">' + data.share_count + '<i class="fa fa-share"></i></a>');
                location.reload();
                notify('Post has been successfully unshared');
             }
         }
     }).fail(function() {
          location.reload();
        });
    });

    $('body').on('click','.share-post',function(e){
        e.preventDefault();
        $.post(SP_source() + 'ajax/get-share-post',{post_id: $(this).data('post-id'),}, function(responseText) {
            if(responseText.status == 200) {
                $('.edit-content').html(responseText.data);
            }
        }).fail(function() {
            location.reload();
        });
        $('#usersModal').modal('show');
        setTimeout(function(){
            jQuery("time.timeago").timeago();
        },100);
    });

    $(document).on('change','#share-option',function(e){
        if($("#share-option").val() == 'friend'){
            $("#friends-box").show();
            $("#group-box").hide();
            $("#page-box").hide();
            initiaizeShare();
        }
        else if($("#share-option").val() == 'group'){
            $("#friends-box").hide();
            $("#group-box").show();
            $("#page-box").hide();
            initiaizeShare();
        }
        else if($("#share-option").val() == 'page'){
            $("#friends-box").hide();
            $("#group-box").hide();
            $("#page-box").show();
            initiaizeShare();
        }
        else{
            $("#friends-box").hide();
            $("#group-box").hide();
            $("#page-box").hide();
        }
    });



    // Timeline Page Liked/Unliked the timeline user  by  logged user
    $(document).on('click','.page-liked',function(e){
      e.preventDefault();
      pagelike_btn = $(this).closest('.page-links');
      $.post(SP_source() + 'ajax/page-liked', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.like == true) {
              pagelike_btn.find('.pageliked').parent().addClass('hidden');
              pagelike_btn.find('.pagelike').parent().removeClass('hidden');
              pagelike_btn.find('.pagelike').closest('.holder').slideToggle();
             }
         }
     });
    });

    // Timeline Group Join/Joined the timeline user  by  logged user
    $(document).on('click','.group-join',function(e){
      e.preventDefault();
      pagelike_btn = $(this).closest('.page-links');
      $.post(SP_source() + 'ajax/group-join', {timeline_id: $(this).data('timeline-id')}, function(data) {
         if (data.status == 200) {
             if (data.join == true) {
              pagelike_btn.find('.joined').parent().addClass('hidden');
              pagelike_btn.find('.join').parent().removeClass('hidden');
              pagelike_btn.find('.join').closest('.holder').slideToggle();
             }
         }
     });
    });

    //DeleteComment  the timeline user  by  logged user
    $('body').on('click','.delete-comment.delete_comment',function(e){
      e.preventDefault();
      commentdelete_btn = $(this).closest('.delete_comment_list');
      $.post(SP_source() + 'ajax/comment-delete', {comment_id: $(this).data('commentdelete-id')}, function(data) {
         if (data.status == 200) {
             if (data.deleted == true) {
                 commentdelete_btn.find('.delete_comment').closest('.comments').slideToggle();
                 notify('You have successfully deleted the comment','warning');
             }
         }
     });
    });
   //Edit comment in the timeline by logged in user
    $('body').on('click','.edit-comment.edit_comment',function(e){
        e.preventDefault();
        comment_box = $(this).closest('.commenter');
        if(comment_box.find('.comment-description').has("img .emoji")){
            var comment_description = comment_box.find('.comment-description').children(".emoji");
            $.each(comment_description, function( key, value ) {
                $(this).replaceWith($(this).attr("title"));
            });
        }
        comment_box.find('.post-comment').val(comment_box.find('.comment-description').text());
        $(this).hide();
        comment_box.find('.delete_comment').hide();
        comment_box.find('.commenter-name').hide();
        comment_box.find('.comment_options').hide();
        comment_box.find('.edit_comment_options').show();
        comment_box.find('.edit-comment-box').show();
    });

    $('body').on('click','.cancel-comment',function(e){
        e.preventDefault();
        comment_box = $(this).closest('.commenter');
        comment_box.find('.edit_comment_options').hide();
        comment_box.find('.edit-comment-box').hide();
        comment_box.find('.delete_comment').show();
        comment_box.find('.edit_comment').show();
        comment_box.find('.commenter-name').show();
        comment_box.find('.comment_options').show();
    });

    //DeleteComment  the timeline user  by  logged user
    $('body').on('click','.delete-post',function(e){
      e.preventDefault();
      postPanel = $(this).closest('.panel-post');
      $.post(SP_source() + 'ajax/post-delete', {post_id: $(this).data('post-id')}, function(data) {
         if (data.status == 200) {
                postPanel.addClass('fadeOut');
                setTimeout(function(){
                    postPanel.remove();
                },800);
                notify('You have successfully deleted the post','warning');
         }
     });
    });

    //ReplyComment  the timeline user  by  logged user
    $(document).on('click','.show-comment-reply',function(e){
      e.preventDefault();
      $(this).parents('.main-comment').find('.comment-reply').slideToggle(100).find('.post-comment').focus();
    });

    //ReplyComment  the timeline user  by  logged user
    $(document).on('click','.show-reply-reply',function(e){
      e.preventDefault();
      var username = $(this).parents('.replyCntr').find('.comment-reply-reply').find('.post-comment').data('username');
      if(username)
        username = "@"+username+" ";
      else
        username = '';

      var form_comment_id = $(this).parents('.main-comment').data('comment-id');
      $(this).parents('.replyCntr').find('.comment-reply-reply').find('.parent_comment_id').val(form_comment_id);
      $(this).parents('.replyCntr').find('.comment-reply-reply').find('.post-comment').val(username);
      $(this).parents('.replyCntr').find('.comment-reply-reply').slideToggle(100).find('.post-comment').focus();
    });

    //Removing member from group
    $(document).on('click','.remove-member',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      group_id = input_ids[1];
      commentdelete_btn = $(this).closest('.follow-links');

      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete member?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/groupmember-remove', {user_id: user_id, group_id: group_id}, function(data) {
           if (data.status == 200) {
             if (data.deleted == true) {
               commentdelete_btn.find('.remove-member').closest('.holder').slideToggle();
               notify('You have successfully deleted the member','warning');
             }else if(data.deleted == false) {
              notify('Assign admin role for member and remove','warning');
             }
           }
         });
       },
       cancel: function(){

       }
     });

     });

     //Removing member from page
    $(document).on('click','.remove-pagemember',function(e){
      e.preventDefault();
      input_ids = $(this).data('user-id').split('-');
      user_id = input_ids[0];
      page_id = input_ids[1];
      commentdelete_btn = $(this).closest('.follow-links');

      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete member?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/pagemember-remove', {user_id: user_id, page_id: page_id}, function(data) {
           if (data.status == 200) {
             if (data.deleted == true) {
               commentdelete_btn.find('.remove-pagemember').closest('.holder').slideToggle();
               notify('You have successfully deleted the member','warning');
             }else if(data.deleted == false) {
              notify('Assign admin role for member and remove','warning');
             }
           }
         });
       },
       cancel: function(){

       }
     });

     });


    $(document).on('click','.edit_page_role',function(e){
      $(this).closest('.roleeditBtn').hide();
      $(this).parent().parent().find('.role_form').show();
    });

    //Delete Page  the timeline user  by  logged user
    $(document).on('click','.delete-page',function(e){
      e.preventDefault();
      pagedelete_btn = $(this).closest('.deletepage');
      page_id = $(this).data('pagedelete-id');
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete page?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/page-delete', {page_id: page_id}, function(data) {
           if (data.status == 200) {
             if (data.deleted == true) {
               pagedelete_btn.find('.delete_page').closest('.deletepage').slideToggle();
             }
           }
         });
       },
       cancel: function(){

       }
     });
    });

    //Delete Group the timeline user  by  logged user
    $(document).on('click','.delete-group',function(e){
      e.preventDefault();
      groupdelete_btn = $(this).closest('.deletegroup');
      group_id = $(this).data('groupdelete-id');
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete group?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/group-delete', {group_id: group_id}, function(data) {
           if (data.status == 200) {
             if (data.deleted == true) {
               groupdelete_btn.hide();
             }
           }
         });
       },
       cancel: function(){

       }
     });
    });

    //Approve Group invitation
    $(document).on('click','.approve-group',function(e){
      e.preventDefault();
      group_id = $(this).data('group-id');
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to approve invitation?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/join-accept', {group_id: group_id}, function(data) {
           if (data.status == 200) {
            location.reload();
           }
         }).fail(function() {
          location.reload();
        });
       },
       cancel: function(){
       }
     });
    });

    //Decline Group invitation
    $(document).on('click','.reject-group',function(e){
      e.preventDefault();
      group_id = $(this).data('group-id');
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to decline invitation?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/join-reject', {group_id: group_id}, function(data) {
           if (data.status == 200) {
            location.reload();
           }
         }).fail(function() {
          location.reload();
        });
       },
       cancel: function(){
       }
     });
    });

    //Delete event list display by logged user
    $(document).on('click','.delete-event',function(e){
      e.preventDefault();
      eventdelete_btn = $(this).closest('.deleteevent');
      event_id = $(this).data('eventdelete-id');
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete event?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/event-delete', {event_id: event_id}, function(data) {
           if (data.status == 200) {
             if (data.deleted == true) {
               eventdelete_btn.find('.delete_event').closest('.deleteevent').slideToggle();
             }
           }
         });
       },
       cancel: function(){

       }
     });
    });

    //Delete notification by logged user
    $('.notification-delete').on('click',function(e){
      e.preventDefault();
      notification_btn = $(this).closest('.notification-delete');
      notification_id = $(this).data('notification-id');
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete notification?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
          $.post(SP_source() + 'ajax/notification-delete', {notification_id: notification_id}, function(data) {
            if (data.status == 200)
            {
              if (data.notify == true)
              {
                notification_btn.closest('tr').hide();
              }
            }
          });
       },
       cancel: function(){

       }
     });
    });

    //Delete event on user timeline by logged user
    $(document).on('click','.event-report',function(e){
      e.preventDefault();
      eventdelete_btn = $(this).closest('.deleteevent');
      input_ids = $(this).data('event-id').split('-');
      event_id = input_ids[0];
      username = input_ids[1];
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete event?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
         $.post(SP_source() + 'ajax/event-delete', {event_id: event_id}, function(data) {
           if (data.status == 200) {
             if (data.deleted == true) {
               window.location = SP_source() + username + '/events';
             }
           }
         });
       },
       cancel: function(){

       }
     });
    });

    // get/stop notifications in the timeline post by user
    $('body').on('click', '.notify-user', function (e) {
      e.preventDefault();
      notify_btn = $(this).closest('.list-inline');
      $.post(SP_source() + 'ajax/notify-user', {post_id: $(this).data('post-id')}, function(data) {
       if (data.status == 200) {
         if (data.notified == true) {
           notify_btn.find('.notify').parent().addClass('hidden');
           notify_btn.find('.unnotify').parent().removeClass('hidden');
         } else {
           notify_btn.find('.notify').parent().removeClass('hidden');
           notify_btn.find('.unnotify').parent().addClass('hidden');
         }
       }
     });
    });

    var validCommentFiles = [];
    $('body').on('click', '.comment_post_btn', function (e) {

      var form_comment_id = $(this).parents('.main-comment').data('comment-id');

    var comment_form = '';
    $(document, '.comment-form').ajaxForm({
        url: SP_source() + 'ajax/post-comment',
        beforeSubmit : function validate(formData, jqForm, options) {
            comment_form = jqForm[0];
            if(comment_form.comment_id){
              comment_id = comment_form.comment_id.value;
            }

            /*if(comment_form.parent_comment_id){
              parent_comment_id = comment_form.parent_comment_id.value;

              if(parent_comment_id == comment_id) {
                parent_comment_id = form_comment_id;
                formData.push({ name:'parent_comment_id', value: parent_comment_id });
              }
            }*/

            //Uploading selected images on create post box
            var hasFile = false;
            for(var i=0; i<=validCommentFiles.length; i++){
                if(validCommentFiles[i] != null)
                {
                    hasFile = true
                    var file = validCommentFiles[i];
                    formData.push({name:'comment_images_upload', value: file})
                }
            }
            if(comment_form.post_comment)
              formData.push({ name:'description', value: comment_form.post_comment.value });

            validCommentFiles = []; // making array empty

            if (!hasFile && !comment_form.post_comment.value) {
                alert("Your post cannot be empty!")
                return false;
            }
        },
        beforeSend: function() {
        },

        success: function(responseText) {
          comment_id = '';
          parent_comment_id = '';
          comment_type = '';

          if(comment_form.comment_images_upload_choice){
            comment_form.comment_images_upload_choice.value = "";
          }

          post_id = comment_form.post_id.value;
          if(comment_form.comment_id){
            comment_id = comment_form.comment_id.value;
          }

          if(comment_form.parent_comment_id){
            parent_comment_id = comment_form.parent_comment_id.value;
          }

          current_post = $("#frmPanelPost" + post_id).find('.post-comment').closest('.panel-post');

          if(comment_id)
          {
            current_post = $("#frmcomment" + comment_id).find('.post-comment').closest('.commenter');
          }

          if(comment_form.comment_type){
            comment_type = comment_form.comment_type.value;
          }
          //create_post_button.attr('disabled', false).find('.fa-spinner').addClass('hidden');
          if (responseText.status == 200) {
                if (comment_type == "update" && comment_id) {
                    current_post.find('.comment-description').html(responseText.comment_description);
                    current_post.find('.edit-comment-box').hide();
                    current_post.find('.edit_comment_options').hide();
                    current_post.find('.comment_options').show();
                    current_post.find('.edit_comment').show();
                    current_post.find('.delete_comment').show();
                    current_post.find('.commenter-name').show();
                    current_post.find('.comment-image-holder-cntr').empty();
                }
                else if(comment_id)
                {
                    // $(current_post).find('.comment-replies').show();
                    // $(current_post).find('.comment-replies').append(responseText.data.original);
                    // $(current_post).find('.commented').find('.post-comment').val('');
                    var commentTag = '.comment' + comment_id;
                    if(!parent_comment_id) {
                      if($(commentTag).hasClass('has-replies'))
                      {
                          $(commentTag).find('.comment-replies').show();
                      }
                      else
                      {
                          $(commentTag).append('<li>' +
                                                  '<div class="comment-replies" style="">' +
                                                      ' <ul class="list-unstyled comment-replys">' +
                                                      '</ul>' +
                                                  '</div>' +
                                              '</li>');
                          $(commentTag).addClass('has-replies');
                      }
                    }

                    if(parent_comment_id) {
                      $(commentTag).append(responseText.data.original);
                      $(commentTag).find('.post-comment').val('');
                      var username = $(commentTag).parent().find('.comment-reply-reply').find('.post-comment').data('username');
                      $(commentTag).parent().find('.comment-reply-reply').find('.post-comment').val('');
                      $(commentTag).parent().find('.comment-reply-reply').find('.comment-image-holder-cntr').empty();
                      $(commentTag).parent().find('.comment-reply-reply').hide();
                    }else{
                      $(commentTag).find('.comment-replies').find('.comment-replys').prepend(responseText.data.original);
                      $(commentTag).find('.post-comment').val('');
                      $(commentTag).find('.comment-image-holder-cntr').empty();
                    }
                }
                else
                {


                    $(current_post).find('div.post-comments-list').prepend(responseText.data.original);
                    $(current_post).find('.post-comment').val('');
                    $(current_post).find('.comment-image-holder-cntr').empty();
                }
                emojify.run();
                mentionify();
             $(current_post).find('.post-comment').val('');
             jQuery("time.timeago").timeago();
             $('.post-comment').height(40);
           }
        }
    });
  });

    //Image upload trigger on post comment
    $(document).on('click','.imageUpload_comment',function(e){
      e.preventDefault();
      $(this).parent().find('.comment-images-upload').addClass("current").trigger('click');
    });

    $(document).on('change','.comment-images-upload.current',function(e){
        e.preventDefault();

        var files = !!this.files ? this.files : [];

        $('.comment-images-selected').find('span').text(files.length);
        $('.comment-images-selected').show('slow');
        $(this).removeClass('current');
        if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

        var countFiles = $(this)[0].files.length;
        var imgPath = $(this)[0].value;
        var extn = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();
        var image_holder = $(this).closest('.comment-form').find('.comment-image-holder-cntr');
        image_holder.empty();
        if (extn == "gif" || extn == "png" || extn == "jpg" || extn == "jpeg") {
            if (typeof(FileReader) != "undefined") {
              validCommentFiles = [];
                //loop for each file selected for uploaded.
                $.each(files, function(key, val){
                    validCommentFiles.push(files[key]);

                    var reader = new FileReader();
                    reader.onload = function(e) {
                        image_holder.append("<div class='individual-image-wrap pip'>" +
                                                 "<img src='"+e.target.result+"' class='thumb-image'>" +
                                                 "<a href='#' data-id=" + (key) + " class='remove-thumb remove-thumb-comment'><i class='fa fa-times-circle' aria-hidden='true'></i></a>" +
                                            "</div>")
                    }
                    image_holder.show();
                    reader.readAsDataURL(files[key]);
                })

              //}
            } else {
              alert("This browser does not support FileReader.");
            }
        } else {
            alert("Pls select only images");
        }
    });

    $(document).on('click','.show-comments',function(e){
        e.preventDefault();
        var comments_section = $(this).closest('.panel-footer').next('.comments-section');

        comments_section.find('.main-comments--cntnr').empty().html('<p style="text-align: left;">Loading...</p>');

        if (comments_section.is(':visible')){

            comments_section.find('.main-comments--cntnr').empty();
        }else{
          // Ajax call to get post all comments.
          var post_id = $(this).data('post-id');

          $.ajax({
              url: SP_source() + 'ajax/post-comments-reply',
              type: 'POST',
              dataType: 'json',
              data: {
                post_id: post_id
              },
              success: function(responseText) {
                  if (responseText.status == 200) {
                    comments_section.find('.main-comments--cntnr').empty().html(responseText.data.original);
                    jQuery("time.timeago").timeago();
                    mentionify();
                    emojify.run();
                  }else{
                    comments_section.find('.main-comments--cntnr').empty().html("<div class='error-text'>" + responseText.message + "</div>");
                  }
              }
          });

        }

        comments_section.slideToggle();
        setTimeout(function(){
            comments_section.find('.post-comment').focus();
        },100);
    });

    $(document).on('click','.show-all-comments',function(e){
        e.preventDefault();

        // Ajax call to get post all comments.
        var all_comments = $(this).closest('.panel-post');

        all_comments.find('.comments-section').find('.main-comments--cntnr').empty().html('<p style="text-align: left;">Loading...</p>');

        if (all_comments.find('.comments-section').is(':visible')){

            all_comments.find('.comments-section').find('.main-comments--cntnr').empty();

        }else{

          var post_id = $(this).data('post-id');

          $.ajax({
              url: SP_source() + 'ajax/post-comments-reply',
              type: 'POST',
              dataType: 'json',
              data: {
                post_id: post_id
              },
              success: function(responseText) {
                  if (responseText.status == 200) {
                    all_comments.find('.comments-section').find('.main-comments--cntnr').empty().html(responseText.data.original);
                    jQuery("time.timeago").timeago();
                    mentionify();
                    emojify.run();
                  }else{
                    all_comments.find('.comments-section').find('.main-comments--cntnr').empty().html("<div class='error-text'>" + responseText.message + "</div>");
                  }
              }
          });
        }

        all_comments.find('.comments-section').slideToggle();
    });

    $(document).on('click','.show-comment-replies',function(e){
      e.preventDefault();
      $(this).next().slideToggle();
    });


    // Change avatar button click event
    $(document).on('click','.change-avatar',function(e){
      e.preventDefault();
      $('.change-avatar-input').trigger('click');
    });

    $(document).on('change','.change-avatar-input',function(e){
      e.preventDefault();
      $('form.change-avatar-form').submit();
    });

    $('form.change-avatar-form').ajaxForm({
         url: SP_source() + 'ajax/change-avatar',

         beforeSend: function() {
             $('.user-avatar-progress').html('0%<br>Uploaded').fadeIn('fast').removeClass('hidden');
         },

         uploadProgress: function(event, position, total, percentComplete) {
             var percentVal = percentComplete+'%';


             $('.user-avatar-progress').html(percentVal+'<br>Uploaded');

             if (percentComplete == 100) {

                 setTimeout(function () {
                     $('.user-avatar-progress').html('Processing');
                     setTimeout(function () {
                         $('.user-avatar-progress').html('Please wait');
                     }, 2000);
                 }, 500);
             }
         },
         success: function(responseText) {

             if (responseText.status == 200) {
                 $('.timeline-user-avtar').find('img')
                     .attr('src', responseText.avatar_url)
                     .load(function() {
                         $('.user-avatar-progress').fadeOut('fast').addClass('hidden').html('');
                         $('.change-avatar-input').val();
                     });
                     $('#profile-image').attr("src",responseText.avatar_url);
                     if( screen.width > 760 ) {
                        allowprofileedit();
                     }
             }
             else {
                 $('.user-avatar-progress').fadeOut('fast').addClass('hidden').html('');
                 $('.change-avatar-input').val();
                 notify(responseText.message,'warning');
             }
         }
     });


    $('form.create-album-form').ajaxForm({
         url: SP_source() + 'ajax/create-album',

         beforeSend: function() {
         },


         success: function(responseText) {

             if (responseText.status == 200) {

             }
             else {

             }
         }
     });

    // Change cover button click event
    $(document).on('click','.change-cover',function(e){
      e.preventDefault();
      $('.change-cover-input').trigger('click');
    });

    $(document).on('change','.change-cover-input',function(e){
      e.preventDefault();
      $('form.change-cover-form').submit();
    });

    $('#createPost').bind('input propertychange', function() {
        $.post( SP_source() + 'ajax/get-youtube-video' , { youtube_source: $('#createPost').val() , csrf_token: $('[name="csrf_token"]').attr('content') })
            .done(function( data ) {
            if(data.status == 200){
                if(data.message.iframe){
                    $('.youtube-iframe').html(data.message.iframe);
                    $('[name="youtube_video_id"]').val(data.message.id);
                    $('[name="youtube_title"]').val(data.message.title);
                }
                else{
                    $('.youtube-iframe').html('');
                    $('[name="youtube_video_id"]').val('');
                    $('[name="youtube_title"]').val('');
                }
            }
        });
    });


    $("#createPost").mention({
        remote: SP_source() + 'ajax/get-users-mentions',
        limit : 10,
    });


    function hashtagify()
    {
        // Lets turn hashtags in the post clickable
        $('.text-wrapper').each(function() {
            $(this).html($(this).html().replace(
                /#([a-zA-Z0-9_]+)/g,
                '<a class="hashtag" href="' + SP_source() + '?hashtag=$1">#$1</a>'
            ));
        });
    }
    hashtagify();
    emojify.run();


    function mentionify()
    {
        // Lets turn usernames in the post clickable
        $('.text-wrapper, .comment-description').each(function() {
            $(this).html($(this).html().replace(
                /@([a-zA-Z0-9_.]+)/g,
                '<a class="hashtag" href="' + SP_source() + '$1">@$1</a>'
            ));
        });
    }
    mentionify();


    $('form.change-cover-form').ajaxForm({
         url: SP_source() + 'ajax/change-cover',

         beforeSend: function() {
             $('.user-cover-progress').html('0%<br>Uploaded').fadeIn('fast').removeClass('hidden');
         },

         uploadProgress: function(event, position, total, percentComplete) {
             var percentVal = percentComplete+'%';


             $('.user-cover-progress').html(percentVal+'<br>Uploaded');

             if (percentComplete == 100) {

                 setTimeout(function () {
                     $('.user-cover-progress').html('Processing');
                     setTimeout(function () {
                         $('.user-cover-progress').html('Please wait');
                     }, 2000);
                 }, 500);
             }
         },
         success: function(responseText) {

             if (responseText.status == 200) {
                 $('.timeline-cover').find('img')
                     .attr('src', responseText.cover_url)
                     .load(function() {
                         $('.user-cover-progress').fadeOut('fast').addClass('hidden').html('');
                         $('.change-cover-input').val();
                     });
                  if( screen.width > 760 ) {
                    allowcoveredit();
                  }
             }
             else {
                 $('.user-cover-progress').fadeOut('fast').addClass('hidden').html('');
                 $('.change-cover-input').val();
                notify(responseText.message,'warning');

             }
         }
     });

     //Image upload trigger on create post    // Change cover button click event
       $(document).on('click','#imageUpload',function(e){
         e.preventDefault();
         $('.post-images-upload').trigger('click');
       });

       $(document).on('click','#selfVideoUpload',function(e){
         e.preventDefault();
         $('.post-video-upload').trigger('click');
       });

       $(document).on('click','#albumImageUpload',function(e){
         e.preventDefault();
         $('.album-images-upload').trigger('click');
       });

    $(document).on('change','.post-images-upload',function(e){
        e.preventDefault();

        var files = !!this.files ? this.files : [];

        $('.post-images-selected').find('span').text(files.length);
        $('.post-images-selected').show('slow');
        if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

        var countFiles = $(this)[0].files.length;
        var imgPath = $(this)[0].value;
        var extn = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();
        var image_holder = $("#post-image-holder");
        image_holder.empty();
        if (extn == "gif" || extn == "png" || extn == "jpg" || extn == "jpeg") {
            if (typeof(FileReader) != "undefined") {
              validFiles = [];

                //loop for each file selected for uploaded.
                $.each(files, function(key, val){
                    validFiles.push(files[key]);

                    var reader = new FileReader();
                    reader.onload = function(e) {
                        image_holder.append("<div class='individual-image-wrap pip'>" +
                                                 "<img src='"+e.target.result+"' class='thumb-image'>" +
                                                 "<a href='#' data-id=" + (key) + " class='remove-thumb'><i class='fa fa-times-circle' aria-hidden='true'></i></a>" +
                                            "</div>")

                    }
                    image_holder.show();
                    reader.readAsDataURL(files[key]);
                })

              //}
            } else {
              alert("This browser does not support FileReader.");
            }
        } else {
            alert("Pls select only images");
        }
    });

    // Removing comments selected image here
    $('body').on('click','.remove-thumb-comment',function(e){
        e.preventDefault()
        var key = $(this).data('id');
        $(this).parent().parent().closest('form.comment-form').find('.comment-images-upload').val('');
        $(this).parent(".pip").remove();
    });

    // Removing selected image here
    $('body').on('click','.remove-thumb',function(e){
        e.preventDefault()
        var count = 0;
        var key = $(this).data('id');
        validFiles[key] = null;
        $(this).parent(".pip").remove();

        $.each(validFiles, function(key,val) {
          if(val != null){
            count++;
          }
        });

        $('.post-images-selected').find('span').text(count);
    });

        $(document).on('change','.post-video-upload',function(e){
         e.preventDefault();
            var files = !!this.files ? this.files : [];

         if((files[0].size/1024)/1024 < 100)
         {

           $('.post-video-selected').find('span').text(files[0]['name']);
           $('.post-video-selected').show('slow');
           if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support
         }
         else
         {
            $('.post-video-upload').val("");
            alert('file size is more than 100 MB');
         }

       });

        $(document).on('change','.album-images-upload',function(e){
         e.preventDefault();

         var files = !!this.files ? this.files : [];

         $('.post-images-selcted').find('span').text(files.length);
         $('.post-images-selcted').show('slow');
         if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

         var countFiles = $(this)[0].files.length;
         var imgPath = $(this)[0].value;
         var extn = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();
         var image_holder = $(".albums-list .row");
         image_holder.empty();
         if (extn == "gif" || extn == "png" || extn == "jpg" || extn == "jpeg") {
           if (typeof(FileReader) != "undefined") {
             //loop for each file selected for uploaded.
             for (var i = 0; i < countFiles; i++)
             {
               var reader = new FileReader();
               reader.onload = function(e) {

                image_holder.append('<div class="col-md-4 album-images">' +
                                    '<div class="album">' +
                                        '<img src="' + e.target.result + '" alt="images">' +
                                        '<a class="btn btn-remove"><i class="fa fa-times" aria-hidden="true"></i></a>' +
                                    '</div>' +
                                    '</div>');

                 // $("<img />", {
                 //   "src": e.target.result,
                 //   "class": "thumb-image"
                 // }).appendTo(image_holder);
               }
               image_holder.show();
               reader.readAsDataURL($(this)[0].files[i]);
             }
           } else {
             alert("This browser does not support FileReader.");
           }
         } else {
           alert("Pls select only images");
         }
       });


      //  Navbar Search suggestions
       var bigSearchUrl = $('#navbar-search').data('url');

       $('#navbar-search').selectize({
           valueField: 'username',
           labelField: 'name',
           optgroupField: 'type',
           searchField: 'name',
           optgroups: [
               {value: 'user', label: 'Users'},
               {value: 'group', label: 'Groups'},
               {value: 'page', label: 'Pages'},
               {value: 'event', label: 'Events'}
           ],

           render: {
               option: function(item, escape) {

                //  get default images
                var item_image = "default-male-avatar.png";
                if(item.type=="group")
                {
                  item_image = "default-group-avatar.png";
                }
                else if(item.type == "page")
                {
                  item_image = "default-page-avatar.png";
                }
                else if(item.type == "event")
                {
                  item_image = "default-group-avatar.png";
                }


               if (item.avatar_url[0] != null)
               {
                   var photo_content = '<a class="media-left" href="'+ SP_source() + item.type + '/avatar/' + escape(item.avatar_url[0].source) + '">' +
                     '<img src="'+ SP_source() + item.type +'/avatar/' + escape(item.avatar_url[0].source) + '" alt="...">' +
                   '</a>';
               }
               else
               {
                   var photo_content = '<a class="media-left" href="#">' +
                     '<img src="'+ SP_source()  + item.type + '/avatar/' + item_image + '" alt="...">' +
                   '</a>';
               }

               if(item.about != null)
               {
                   var about = escape(item.about);
               }
               else
               {
                   var about = '(no description added)';
               }

               var verified = '';

               if(item.verified == 1)
               {
                  var verified = '<span class="verified-badge verified-small bg-success"> <i class="fa fa-check"></i></span>';
               }

             return '<div class="media big-search-dropdown">' + photo_content +
               '<div class="media-body">' +
                 '<h4 class="media-heading">' + escape(item.name) + verified + ' </h4>' +
                  '<p>' +  about +  '</p>' +               '</div>' +
             '</div>';

           },
           optgroup_header: function(data, escape) {
               return '<div class="optgroup-header">' + escape(data.label) + '</div>';
           }
           },
           onChange: function(value)
           {
              window.location.href = SP_source() +  value;
           },
           load: function(query, callback) {
               if (!query.length) return callback();
               $.ajax({
                   url: bigSearchUrl,
                   type: 'GET',
                   dataType: 'json',
                   data: {
                       search: query
                   },
                   error: function() {
                       callback();
                   },
                   success: function(res) {
                       callback(res.data);
                   }
               });
           }
       });

       var bigSearchUrl = $('#navbar-search-md').data('url');

       $('#navbar-search-md').selectize({
           create: true,
           selectOnTab: true,
           valueField: 'username',
           labelField: 'name',
           optgroupField: 'type',
           searchField: ['name','username'],
           optgroups: [
               {value: 'user', label: 'Users'},
               {value: 'group', label: 'Groups'},
               {value: 'page', label: 'Pages'},
               {value: 'event', label: 'Events'}
           ],

           render: {
               option: function(item, escape) {

                //  get default images
                var item_image = "default-male-avatar.png";
                if(item.type=="group")
                {
                  item_image = "default-group-avatar.png";
                }
                else if(item.type == "page")
                {
                  item_image = "default-page-avatar.png";
                }
                else if(item.type == "event")
                {
                  item_image = "default-group-avatar.png";
                }


               if (item.avatar_url[0] != null)
               {
                   var photo_content = '<a class="media-left" href="'+ SP_source() + item.type + '/avatar/' + escape(item.avatar_url[0].source) + '">' +
                     '<img src="'+ SP_source() + item.type +'/avatar/' + escape(item.avatar_url[0].source) + '" alt="...">' +
                   '</a>';
               }
               else
               {
                   var photo_content = '<a class="media-left" href="#">' +
                     '<img src="'+ SP_source()  + item.type + '/avatar/' + item_image + '" alt="...">' +
                   '</a>';
               }

               if(item.about != null)
               {
                   var about = escape(item.about);
               }
               else
               {
                   var about = '(no description added)';
               }

               var verified = '';

               if(item.verified == 1)
               {
                  var verified = '<span class="verified-badge verified-small bg-success"> <i class="fa fa-check"></i></span>';
               }

             return '<div class="media big-search-dropdown">' + photo_content +
               '<div class="media-body">' +
                 '<h4 class="media-heading timeline_search">' + escape(item.name) + verified + ' </h4>' +
                  '<p>' +  about +  '</p>' +               '</div>' +
             '</div>';

           },
           option_create:function(data){
                if ($("#search_users .timeline_search").length == 0) {
                     return '<div class="media big-search-dropdown">' +
                                '<div class="media-body">' +
                                    '<h4 class="media-heading">No Results found</h4>' +
                                    '<p></p>' +
                               '</div>' +
                            '</div>';
                }
           },
           optgroup_header: function(data, escape) {
               return '<div class="optgroup-header">' + escape(data.label) + '</div>';
           }
           },
           onChange: function(value)
           {
              window.location.href = SP_source() +  value;
           },
           load: function(query, callback) {
               if (!query.length) return callback();
               $.ajax({
                   url: bigSearchUrl,
                   type: 'GET',
                   dataType: 'json',
                   data: {
                       search: query
                   },
                   error: function() {
                       callback();
                   },
                   success: function(res) {
                       callback(res.data);
                   }
               });
           }
       });
         //  Create post user tags
       var bigSearchUrl = $('#navbar-search').data('url');

        var selectizeUsers = $('#userTags').selectize({
            valueField: 'id',
            labelField: 'name',
            searchField: ['name','username'],
            plugins: ['remove_button'],
            render: {
                option: function(item, escape) {
                    if(item.about != null)
                    {
                        var about = escape(item.about);
                    }
                    else
                    {
                        var about = '(no description added)';
                    }

                    return '<div class="media big-search-dropdown">' +
                        '<a class="media-left" href="#">' +
                            '<img src="'+ item.avatar_link + '" alt="...">' +
                        '</a>' +
                    '<div class="media-body">' +
                        '<h4 class="media-heading">' + escape(item.name) + '</h4>' +
                        '<p>' +  about +  '</p>' +               '</div>' +
                    '</div>';
                },

           },
           onChange: function(value)
           {
                $('[name="user_tags"]').val(value);
                // $('.user-tags-added').find('.user-tag-names').append('<a href="#">' + value  + '</a>');
                        var selectize =selectizeUsers[0].selectize;
                  var values = selectize.items;

                  getUsersData();
           },
              load: function(query, callback) {
               if (!query.length) return callback();
               $.ajax({
                   url: base_url  + 'ajax/search-friends',
                   type: 'GET',
                   dataType: 'json',
                   data: {
                       search: query
                   },
                   error: function() {
                       callback();
                   },
                   success: function(res) {
                       callback(res.data);
                   }
               });
           }
       });

         function getUsersData() {
                var selectize = selectizeUsers[0].selectize;
            var values = selectize.getValue();
          var array = values.split(',');
          var selectedUserTags = ''
          $.each(array, function(key, value) {
            if(value){
              selectedUserTags = selectedUserTags  + '<a href="#">' + selectize.options[value].name  + '</a>, ';
            }
          });

            $('.user-tags-added').find('.user-tag-names').html(selectedUserTags);

          // $.each(values,function(value){
          // });
          // return $.map(values, function(value) {
          //   return selectize.options[value];
          // });
        }

       $('.timeline-posts').jscroll({
            // loadingHtml: '<img src="loading.gif" alt="Loading" /> Loading...',
            nextSelector: 'a.jscroll-next:last',
            callback : function()
            {
                emojify.run();
                hashtagify();
                mentionify();
                jQuery("time.timeago").timeago();
            }
        });
        //gmail contacts handling
        $("#gmail_contact_select_all").change(function(){
            if($(this).is(":checked")){
                $("#gmail_contact_select_all_label").html("Deselect All");
                $(".gmail_contact_email").prop("checked", true);
                $("#send_notifications").prop("disabled", false);
            }
            else{
                $("#gmail_contact_select_all_label").html("Select All");
                $(".gmail_contact_email").prop("checked", false);
                $("#send_notifications").prop("disabled", true);
            }
        });

        $(".gmail_contact_email").change(function(){
            if (!$(".gmail_contact_email").is(":checked")){
                $("#send_notifications").prop("disabled", true);
            }
            else{
                $("#send_notifications").prop("disabled", false);
            }
        });

// Adding members to the group
      $('.add-members-group').on('keyup',function(){
        $('.group-suggested-users').empty();
        groupId = $(this).data('group-id');
        if($(this).val() != null && $(this).val() != "")
        $.post( SP_source() + 'ajax/get-users' , { searchname: $(this).val() ,group_id: groupId, csrf_token: $('[name="csrf_token"]').attr('content') })
          .done(function( responseText ) {

            if(responseText.status == 200)
            {
              $('.group-suggested-users').empty();
              var users_results = responseText.data;

              $.each(users_results, function(key, value) {

                var user = value[0];
                var joinStatus = '';
                var user_id = '';
                var group_id = '';
                var disableButton = false;

                if(user.groups[0] != null)
                {
                   user_id = user.groups[0].pivot.user_id;
                   group_id = user.groups[0].pivot.group_id;

                  if (user.groups[0].pivot.status == "pending") {
                    if (user.groups[0].pivot.invited_by !== 0) {
                      joinStatus = 'Invited';
                    } else {
                      joinStatus = 'Join Requested';
                      disableButton = true;
                    }
                  } else if (user.groups[0].pivot.status == "approved") {
                    joinStatus = 'Joined';
                    disableButton = true;
                  }
                }
                else
                {
                  user_id = user.id;
                  group_id = groupId;
                  joinStatus = 'Invite';
                }


                 if(user.avatar_id != null){
                    avatarSource = user.avatar_url[0].source;

                  }else{
                    avatarSource = "default-"+user.gender+"-avatar.png";
                  }

                  $('.group-suggested-users').append('<div class="holder">' +
                    '<div class="follower side-left side-left-visible">' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<img src="' + SP_source() + 'user/avatar/'+ avatarSource +'" alt="images">' +
                      '</a>' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<span>' + user.name + '</span>' +
                      '</a>' +
                    '</div>' +
                    '<div class="follow-links side-right">' +
                      '<div class="left-col">' +
                        '<a href="#" class="btn btn-to-follow btn-default add-member add ' + (disableButton ? 'disabled' : '') + '" data-user-id="'+user_id+' - '+group_id+'-'+joinStatus+'">' + joinStatus + '</a>' +
                      '</div>' +
                    '</div>' +
                    '<div class="clearfix"></div>'+
                    '</div>');

              });
            }
        });
      });

      // Adding members to the event
      $('#add-members-event').on('keyup',function(){
        $('.event-suggested-users').empty();
        if($('#add-members-event').val() != null && $('#add-members-event').val() != "")
          eventId = $(this).data('event-id');
        $.post( SP_source() + 'ajax/get-members-invite', { searchname: $('#add-members-event').val() ,event_id: eventId, csrf_token: $('[name="csrf_token"]').attr('content') })
          .done(function( responseText ) {

            if(responseText.status == 200)
            {
              var users_results = responseText.data;

              $.each(users_results, function(key, value) {

                var user = value[0];
                var joinStatus = '';
                var user_id = '';
                var event_id = '';

                if(user.events[0] != null)
                {
                   user_id = user.events[0].pivot.user_id;
                   event_id = user.events[0].pivot.event_id;
                   joinStatus = 'Invited';
                }
                else
                {
                  user_id = user.id;
                  event_id = eventId;
                  joinStatus = 'Invite';
                }


                 if(user.avatar_id != null){
                    avatarSource = user.avatar_url[0].source;

                  }else{
                    avatarSource = "default-"+user.gender+"-avatar.png";
                  }

                  $('.event-suggested-users').append('<div class="holder">' +
                    '<div class="follower side-left side-left-visible">' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<img src="' + SP_source() + 'user/avatar/'+ avatarSource +'" alt="images">' +
                      '</a>' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<span>' + user.name + '</span>' +
                      '</a>' +
                    '</div>' +
                    '<div class="follow-links side-right">' +
                      '<div class="left-col">' +
                        '<a href="#" class="btn btn-to-follow btn-default add-event-member  add" data-user-id="'+user_id+' - '+event_id+'-'+joinStatus+'">' + joinStatus + '</a>' +
                      '</div>' +
                    '</div>' +
                    '<div class="clearfix"></div>'+
                    '</div>');
              });
            }
        });
      });


//Adding members to the page

$('#add-members-page').on('keyup',function(){
        $('.page-suggested-users').empty();
        if($('#add-members-page').val() != null && $('#add-members-page').val() != "")
          pageId = $(this).data('page-id');
        $.post( SP_source() + 'ajax/get-members-join' , { searchname: $('#add-members-page').val() ,page_id: pageId, csrf_token: $('[name="csrf_token"]').attr('content') })
          .done(function( responseText ) {

            if(responseText.status == 200)
            {
              var users_results = responseText.data;

              $.each(users_results, function(key, value) {

                var user = value[0];
                var joinStatus = '';
                var user_id = '';
                var page_id = '';

                if(user.pages[0] != null)
                {
                  user_id = user.pages[0].pivot.user_id;
                  page_id = user.pages[0].pivot.page_id;
                  joinStatus = 'Joined';
                }
                else
                {
                  user_id = user.id;
                  page_id = pageId;
                  joinStatus = 'Join';
                }


                 if(user.avatar_id != null){
                    avatarSource = user.avatar_url[0].source;

                  }else{
                    avatarSource = "default-"+user.gender+"-avatar.png";
                  }

                  $('.page-suggested-users').append('<div class="holder">' +
                    '<div class="follower side-left side-left-visible">' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<img src="' + SP_source() + 'user/avatar/'+ avatarSource +'" alt="images">' +
                      '</a>' +
                      '<a href="' +  SP_source() + user.username + '">' +
                        '<span>' + user.name + '</span>' +
                      '</a>' +
                    '</div>' +
                    '<div class="follow-links side-right">' +
                      '<div class="left-col">' +
                        '<a href="#" class="btn btn-to-follow btn-default add-page-member  add" data-user-id="'+user_id+' - '+page_id+'-'+joinStatus+'">' + joinStatus + '</a>' +
                      '</div>' +
                    '</div>' +
                    '<div class="clearfix"></div>'+
                    '</div>');

              });
            }
        });
      });


        $('.postmessage').on('keypress',function(e) {
            if(e.keyCode==13)
            {
                e.preventDefault();
                $.post(SP_source() + 'ajax/post-message',{conversation_id: $('.conversation-id').val(), description: $(this).val()}, function(responseText) {
                    $('.post-message').val('');
                    $('.coversations-thread').append(responseText.data);
                    jQuery("time.timeago").timeago();
                    emojify.run();
                 });
            }
        });
        // chat-list-toggle
        $('.chat-list-toggle').on('click',function(e){
            e.preventDefault();
            $('.chat-list').animate({width: 'toggle'});
            $('.chat-box').slideToggle();
        });
        // for timeline-list toggle in small screens
        $('.btn-status').on('click',function(e){
            // $('.timeline-list .list-inline').slideToggle('slow');
              // $('.timeline-list .list-inline').toggle('slow');
              e.preventDefault();
               if($(window).width() < 1200) {

                 $('.timeline-list .list-inline').slideToggle('slow');
              }
        });
        $(window).on('resize',function(){
            var win = $(this);
           if (win.width()>= 1200){
          $('.timeline-list .list-inline').show('slow');
        }
        });



        //smooth scroll intialization

        $(".smooth-scroll").mCustomScrollbar("scrollTo","bottom",{
            autoHideScrollbar:true,
            theme:"rounded",
            mouseWheel:{ preventDefault: true }
        });

        //tooltip intialization
         $('[data-toggle="tooltip"]').tooltip();

        //date-picker
        $( "#datepicker" ).datepicker();
        $( "#datepicker1" ).datepicker();
        $( "#datepicker2" ).datepicker();

        // focus fix for input
        $('.input-group-addon').on('click',function(){
          $(this).parents('.input-group').find('.form-control').trigger('select');
        });
         $('.input-group .form-control').on('focus',function(){
          $(this).parents('.input-group').addClass('input-group-focus');
        });
        $('.input-group .form-control').on('blur',function(){
          $(this).parents('.input-group').removeClass('input-group-focus');
        });


function notify(message,type,layout)
{
     var n = noty({
       text: message,
       layout: 'bottomLeft',
       type : type ? type : 'success',
       theme : 'relax',
       timeout:1,
       animation: {
           open: 'animated fadeIn', // Animate.css class names
           close: 'animated fadeOut', // Animate.css class names
           easing: 'swing', // unavailable - no need
           speed: 500 // unavailable - no need
       }
   });
   n.setTimeout(3000);
};

function readURL(input, imageId) {

    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $(imageId).attr('src', e.target.result);
        }

        reader.readAsDataURL(input.files[0]);
    }
}

$("#imgInp").change(function(){
    readURL(this,"#blah");
});

$(".settings_switch").change(function(){
  // $(this).parent('.email_follower').css("color",this.checked ? "red" : "#354052");
  alert('vj');
});

//WYSIWYG EDITOR(TinyMCE)
  tinymce.init({
  selector: '.mytextarea',
  theme: 'modern',
  height : 84,
  max_width: 884.25,
  plugins: [
    'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker',
    'searchreplace wordcount visualblocks visualchars  code fullscreen insertdatetime media nonbreaking',
    'save table contextmenu directionality emoticons template paste textcolor'
  ],
  content_css: '../../../themes/default/assets/css/tinymce.css',
  toolbar: 'bold italic underline strikethrough | link blockquote image code | bullist  numlist alignjustify aligncenter alignleft alignright',
  menubar: true,
  statusbar: false,
  resize: true,

  });

  $('.add_selectize').selectize({
        plugins: ['drag_drop'],
        delimiter: ',',
        persist: false,
        create: function(input) {
            return {
                value: input,
                text: input
            }
        }
    });

  //Delete notification by logged user
    $('.allnotifications-delete').on('click',function(e){
      e.preventDefault();
      notification_btn = $(this).closest('.allnotifications-delete');
      $.confirm({
        title: 'Confirm!',
        content: 'Do you want to delete all notifications?',
        confirmButton: 'Yes',
        cancelButton: 'No',
        confirmButtonClass: 'btn btn-primary orange-color',
        cancelButtonClass: 'btn btn-danger grey-color',

        confirm: function(){
          $.post(SP_source() + 'ajax/allnotifications-delete', {}, function(data) {
            if (data.status == 200)
            {
              if (data.allnotify == true)
              {
                window.location = SP_source() + 'allnotifications';
              }
            }
          });
       },
       cancel: function(){

       }
     });
    });
    $('.light-album').lightGallery({
      selector: '.btn-lightgallery'
    });
    $('.set-fav-mh').matchHeight();

  $('#fileupload').on('change', function() {
    var context = $('<div/>').appendTo('#files');
    $.each(this.files, function (index, file) {
        var node = $('<p/>')
                .append($('<span/>').text(file.name));
        node.appendTo(context);
    });
  });

  $('#photo_fileupload').on('change', function(e) {
    var context = $('<div/>').appendTo('#files');
    $.each(this.files, function (index, file) {

      var reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = function () {
         var node = $('<p/>')
                .append($('<span/>').html("<div class='individual-image-wrap pip'>" +
                                                 "<img width='50' height='50' src='"+reader.result+"' class='thumb-image'>" +
                                            "</div>"));

        node.appendTo(context);

       };
    });
  });


//----------- Adcenter -----------


    $(document).on('click','.show-ads-comments',function(e){
        e.preventDefault();
        var comments_section = $(this).closest('.panel-footer').next('.comments-section');

        comments_section.find('.main-comments--cntnr').empty().html('<p style="text-align: left;">Loading...</p>');

        if (comments_section.is(':visible')){

            comments_section.find('.main-comments--cntnr').empty();
        }else{
          // Ajax call to get ads all comments.
          var ad_id = $(this).data('ad-id');

          $.ajax({
              url: SP_source() + 'ajax/ads-comments-reply',
              type: 'POST',
              dataType: 'json',
              data: {
                ad_id: ad_id
              },
              success: function(responseText) {
                  if (responseText.status == 200) {
                    comments_section.find('.main-comments--cntnr').empty().html(responseText.data.original);
                    jQuery("time.timeago").timeago();
                    emojify.run();
                    //mentionify();
                  }else{
                    comments_section.find('.main-comments--cntnr').empty().html("<div class='error-text'>" + responseText.message + "</div>");
                  }
              }
          });

        }

        comments_section.slideToggle();
        setTimeout(function(){
            comments_section.find('.ad-comment').focus();
        },100);
    });


    var validadCommentFiles = [];
    $('body').on('click', '.ad_comment_post_btn', function (e) {

        var form_comment_id = $(this).parents('.main-comment').data('comment-id');
        var adcomment_form = '';

        $(document, '.ad-comment-form').ajaxForm({
            url: SP_source() + 'ajax/ads-comment',
            beforeSubmit : function validate(formData, jqForm, options) {

                adcomment_form = jqForm[0];
                if(adcomment_form.comment_id){
                  comment_id = adcomment_form.comment_id.value;
                }

                //Uploading selected images on create post box
                var hasFile = false;
                for(var i=0; i<=validadCommentFiles.length; i++){
                    if(validadCommentFiles[i] != null)
                    {
                        hasFile = true
                        var file = validadCommentFiles[i];
                        formData.push({name:'comment_images_upload', value: file})
                    }
                }
                if(adcomment_form.ad_comment)
                    formData.push({ name:'description', value: adcomment_form.ad_comment.value });

                validadCommentFiles = []; // making array empty

                if (!hasFile && !adcomment_form.ad_comment.value) {
                    alert("Your post cannot be empty!")
                    return false;
                }
            },

            success: function(responseText) {
                comment_id = '';
                parent_comment_id = '';
                comment_type = '';
                if(adcomment_form.ad_comment_images_upload_choice){
                    adcomment_form.ad_comment_images_upload_choice.value = "";
                }

                ad_id = adcomment_form.ad_id.value;
                if(adcomment_form.comment_id){
                    comment_id = adcomment_form.comment_id.value;
                }

                if(adcomment_form.parent_comment_id){
                    parent_comment_id = adcomment_form.parent_comment_id.value;
                }

                current_post = $(".frmadPanelPost" + ad_id).find('.ad-comment').closest('.panel-post');

                if(comment_id)
                {
                    current_post = $(".frmadcomment" + comment_id).find('.ad-comment').closest('.commenter');
                }

                if(adcomment_form.comment_type){
                    comment_type = adcomment_form.comment_type.value;
                }

                //create_post_button.attr('disabled', false).find('.fa-spinner').addClass('hidden');
                if (responseText.status == 200) {
                    if (comment_type == "update" && comment_id) {

                        current_post.find('.comment-description').html(responseText.comment_description);
                        current_post.find('.edit-comment-box').hide();
                        current_post.find('.edit_comment_options').hide();
                        current_post.find('.comment_options').show();
                        current_post.find('.edit_adcomment').show();
                        current_post.find('.delete_adcomment').show();
                        current_post.find('.commenter-name').show();
                        current_post.find('.comment-image-holder-cntr').empty();
                    }
                    else if(comment_id)
                    {
                        var commentTag = '.ad-comment' + comment_id;

                        if(!parent_comment_id) {
                            if($(commentTag).hasClass('has-replies'))
                            {

                                $(commentTag).find('.ad-comment-replies').show();
                            }
                            else
                            {
                                $(commentTag).append('<li>' +
                                                      '<div class="ad-comment-replies" style="">' +
                                                          ' <ul class="list-unstyled ad-comment-replys">' +
                                                          '</ul>' +
                                                      '</div>' +
                                                  '</li>');
                                $(commentTag).addClass('has-replies');
                            }
                        }

                        if(parent_comment_id) {

                            $(commentTag).append(responseText.data.original);
                            $(commentTag).find('.ad-comment').val('');
                            var username = $(commentTag).parent().find('.ad-comment-reply-reply').find('.ad-comment').data('username');
                            $(commentTag).parent().find('.ad-comment-reply-reply').find('.ad-comment').val('');
                            $(commentTag).parent().find('.ad-comment-reply-reply').find('.comment-image-holder-cntr').empty();
                            $(commentTag).parent().find('.ad-comment-reply-reply').hide();
                        }else{

                            $(commentTag).find('.ad-comment-replies').find('.ad-comment-replys').prepend(responseText.data.original);
                            $(commentTag).find('.ad-comment').val('');
                            $(commentTag).find('.comment-image-holder-cntr').empty();
                        }
                    }
                    else
                    {
                        $(current_post).find('div.post-comments-list').prepend(responseText.data.original);
                        $(current_post).find('.ad-comment').val('');
                        $(current_post).find('.comment-image-holder-cntr').empty();
                    }

                    mentionify();
                    emojify.run();
                    $(current_post).find('.ad-comment').val('');
                    jQuery("time.timeago").timeago();
                    $('.ad-comment').height(40);
                }
            }
        });
    });

    //DeleteComment  the timeline user  by  logged user
    $('body').on('click','.delete-comment.delete_adcomment',function(e){
        e.preventDefault();
        var comment_id = $(this).data('commentdelete-id');
        commentdelete_btn = $(this).closest('.delete_adcomment_list');
        $.post(SP_source() + 'ajax/ads-comment-delete', {comment_id: $(this).data('commentdelete-id')}, function(data) {
            if (data.status == 200) {
                if (data.deleted == true) {

                    //commentdelete_btn.find('.delete_adcomment').closest('.comments').slideToggle();
                    $(".dele-comments"+comment_id).slideToggle();
                }
            }
        });
    });

   //Edit comment in the timeline by logged in user
    $('body').on('click','.edit-comment.edit_adcomment',function(e){

        e.preventDefault();
        comment_box = $(this).closest('.commenter');
        if(comment_box.find('.comment-description').has("img .emoji")){
            var comment_description = comment_box.find('.comment-description').children(".emoji");
            $.each(comment_description, function( key, value ) {
                $(this).replaceWith($(this).attr("title"));
            });
        }

        comment_box.find('.ad-comment').val(comment_box.find('.comment-description').text());
        $(this).hide();
        comment_box.find('.delete_adcomment').hide();
        comment_box.find('.commenter-name').hide();
        comment_box.find('.comment_options').hide();
        comment_box.find('.edit_comment_options').show();
        comment_box.find('.edit-comment-box').show();
    });

    $('body').on('click','.cancel-adcomment',function(e){
        e.preventDefault();
        comment_box = $(this).closest('.commenter');
        comment_box.find('.edit_comment_options').hide();
        comment_box.find('.edit-comment-box').hide();
        comment_box.find('.delete_adcomment').show();
        comment_box.find('.edit_adcomment').show();
        comment_box.find('.commenter-name').show();
        comment_box.find('.comment_options').show();
    });

    $(document).on('click','.show-all-ads-comments',function(e){
        e.preventDefault();

        // Ajax call to get post all comments.
        var all_comments = $(this).closest('.panel-post');

        all_comments.find('.comments-section').find('.main-comments--cntnr').empty().html('<p style="text-align: left;">Loading...</p>');

        if (all_comments.find('.comments-section').is(':visible')){

            all_comments.find('.comments-section').find('.main-comments--cntnr').empty();

        }else{

            var ad_id = $(this).data('ad-id');

            $.ajax({
                url: SP_source() + 'ajax/ads-comments-reply',
                type: 'POST',
                dataType: 'json',
                data: {
                    ad_id: ad_id
                },
                success: function(responseText) {
                    if (responseText.status == 200) {
                        all_comments.find('.comments-section').find('.main-comments--cntnr').empty().html(responseText.data.original);
                        jQuery("time.timeago").timeago();
                        mentionify();
                        emojify.run();
                    }else{
                        all_comments.find('.comments-section').find('.main-comments--cntnr').empty().html("<div class='error-text'>" + responseText.message + "</div>");
                    }
                }
            });
        }

        all_comments.find('.comments-section').slideToggle();
    });

    //Image upload trigger on post comment
    $(document).on('click','.imageUpload_adcomment',function(e){
        e.preventDefault();
        $(this).parent().find('.ad-comment-images-upload').addClass("current").trigger('click');
    });

    $(document).on('change','.ad-comment-images-upload.current',function(e){
        e.preventDefault();

        var files = !!this.files ? this.files : [];

        $('.comment-images-selected').find('span').text(files.length);
        $('.comment-images-selected').show('slow');
        $(this).removeClass('current');
        if (!files.length || !window.FileReader) return; // no file selected, or no FileReader support

        var countFiles = $(this)[0].files.length;
        var imgPath = $(this)[0].value;
        var extn = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();
        var image_holder = $(this).closest('.ad-comment-form').find('.comment-image-holder-cntr');
        image_holder.empty();
        if (extn == "gif" || extn == "png" || extn == "jpg" || extn == "jpeg") {
            if (typeof(FileReader) != "undefined") {
              validCommentFiles = [];
                //loop for each file selected for uploaded.
                $.each(files, function(key, val){
                    validCommentFiles.push(files[key]);

                    var reader = new FileReader();
                    reader.onload = function(e) {
                        image_holder.append("<div class='individual-image-wrap pip'>" +
                                                 "<img src='"+e.target.result+"' class='thumb-image'>" +
                                                 "<a href='#' data-id=" + (key) + " class='remove-thumb remove-thumb-adcomment'><i class='fa fa-times-circle' aria-hidden='true'></i></a>" +
                                            "</div>")
                    }
                    image_holder.show();
                    reader.readAsDataURL(files[key]);
                })

              //}
            } else {
              alert("This browser does not support FileReader.");
            }
        } else {
            alert("Please select only images");
        }
    });

    // Removing comments selected image here
    $('body').on('click','.remove-thumb-adcomment',function(e){
        e.preventDefault()
        var key = $(this).data('id');
        $(this).parent().parent().closest('form.ad-comment-form').find('.ad-comment-images-upload').val('');
        $(this).parent(".pip").remove();
    });

    //ReplyComment  the timeline user  by  logged user
    $(document).on('click','.show-ad-comment-reply',function(e){
        e.preventDefault();
        $(this).parents('.main-comment').find('.ad-comment-reply').slideToggle(100).find('.ad-comment').focus();
    });

    $(document).on('click','.show-ad-comment-replies',function(e){
        e.preventDefault();
        $(this).next().slideToggle();
    });


    $(document).on('click','.like-ad-comment',function(e){
        e.preventDefault();
        commentId = $(this).data('comment-id');
        commentlike_btn = $(this).closest('.comments-list');
        $.post(SP_source() + 'ajax/ads-comment-like', {comment_id: $(this).data('comment-id')}, function(data) {
            if (data.status == 200) {
                if (data.liked == true) {
                    commentlike_btn.find('.like').parent().addClass('hidden');
                    commentlike_btn.find('.unlike').parent().removeClass('hidden');
                    $('.comments-list').find('.like3-'+commentId).parent().addClass('hidden');
                    $('.comments-list').find('.like4-'+commentId).parent().find('.like').parent().addClass('hidden');
                    $('.comments-list').find('.like4-'+commentId).parent().find('.unlike').parent().removeClass('hidden');
                    $('.like4-'+commentId).empty();
                    $('.comments-list').find('.like4-'+commentId).removeClass('hidden').append('<a href="#" class=".show-likes">' + data.likecount + '<i class="fa fa-thumbs-up"></i></a>');
                } else {
                    commentlike_btn.find('.like').parent().removeClass('hidden');
                    commentlike_btn.find('.unlike').parent().addClass('hidden');
                    $('.comments-list').find('.like3-'+commentId).parent().addClass('hidden');
                    $('.comments-list').find('.like4-'+commentId).parent().find('.like').parent().removeClass('hidden');
                    $('.comments-list').find('.like4-'+commentId).parent().find('.unlike').parent().addClass('hidden');
                    $('.like4-'+commentId).empty();
                    $('.comments-list').find('.like4-'+commentId).removeClass('hidden').append('<a href="#" class=".show-likes">' + data.likecount + '<i class="fa fa-thumbs-up"></i></a>');
                }
            }
        });
    });

    //ReplyComment  the timeline user  by  logged user
    $(document).on('click','.show-ad-reply-reply',function(e) {

        e.preventDefault();
        var username = $(this).parents('.replyCntr').find('.ad-comment-reply-reply').find('.ad-comment').data('username');
        if(username)
            username = "@"+username+" ";
        else
            username = '';

        var form_comment_id = $(this).parents('.main-comment').data('comment-id');
        $(this).parents('.replyCntr').find('.ad-comment-reply-reply').find('.parent_comment_id').val(form_comment_id);
        $(this).parents('.replyCntr').find('.ad-comment-reply-reply').find('.ad-comment').val(username);
        $(this).parents('.replyCntr').find('.ad-comment-reply-reply').slideToggle(100).find('.ad-comment').focus();
    });

// ------------ Adcenter end here -----------

});

function updateFriendRequestCount() {
    var friends_count = $('#show_friends .count').html();
    var new_friends_count = (friends_count > 0) ? friends_count - 1 : 0;
    if(new_friends_count > 0)
        $('#show_friends .count').html(new_friends_count);
    else
        $('#show_friends .count').addClass('hidden');
}

function allowcoveredit(){
  $('.save-cover').show();
  var image = document.getElementById('cover-image');
  var cropper = new Cropper(image, {
    dragMode: 'move',
    viewMode: 3,
    zoomable: true,
    cropBoxMovable: false,
    cropBoxResizable: false,
    autoCropArea: 1,
    crop: function(e) {
      $('.submit-cover').data('image-info',{x:e.detail.x,y:e.detail.y,width:e.detail.width,height:e.detail.height});
    }
  });
}

function allowprofileedit(){
  $('#profile_modal').modal('show');
  var image = document.getElementById('profile-image');
  var cropper = new Cropper(image, {
    zoomable: true,
    viewMode: 2,
    cropBoxMovable: true,
    cropBoxResizable: true,
    autoCropArea: 0.5,
    crop: function(e) {
      $('.submit-avatar').data('image-info',{x:e.detail.x,y:e.detail.y,width:e.detail.width,height:e.detail.height});
    }
  });
}

function initiaizeShare(){
    var selectizeUsers = $('#friendTag').selectize({
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        maxItems: 1,
        plugins: ['remove_button'],
        render: {
            option: function(item, escape) {
                if(item.about != null)
                {
                    var about = escape(item.about);
                }
                else
                {
                    var about = '(no description added)';
                }

                return '<div class="media big-search-dropdown">' +
                    '<a class="media-left" href="#">' +
                        '<img src="'+ item.avatar_link + '" alt="...">' +
                    '</a>' +
                '<div class="media-body">' +
                    '<h4 class="media-heading">' + escape(item.name) + '</h4>' +
                    '<p>' +  about +  '</p>' +               '</div>' +
                '</div>';
            },

       },
       onChange: function(value)
       {
            $('[name="friend"]').val(value);
            // $('.user-tags-added').find('.user-tag-names').append('<a href="#">' + value  + '</a>');
            var selectize =selectizeUsers[0].selectize;
            var values = selectize.items;
            //getUsersData();
       },
          load: function(query, callback) {
           if (!query.length) return callback();
           $.ajax({
               url: base_url  + 'ajax/search-friends',
               type: 'GET',
               dataType: 'json',
               data: {
                   search: query
               },
               error: function() {
                   callback();
               },
               success: function(res) {
                   callback(res.data);
               }
           });
       }
   });

    var selectizeGroups = $('#groupTag').selectize({
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        maxItems: 1,
        plugins: ['remove_button'],
        render: {
            option: function(item, escape) {
                if(item.about != null)
                {
                    var about = escape(item.about);
                }
                else
                {
                    var about = '(no description added)';
                }

                return '<div class="media big-search-dropdown">' +
                    '<a class="media-left" href="#">' +
                        '<img src="'+ item.avatar_link + '" alt="...">' +
                    '</a>' +
                '<div class="media-body">' +
                    '<h4 class="media-heading">' + escape(item.name) + '</h4>' +
                    '<p>' +  about +  '</p>' +               '</div>' +
                '</div>';
            },

       },
       onChange: function(value)
       {
            $('[name="group"]').val(value);
            // $('.user-tags-added').find('.user-tag-names').append('<a href="#">' + value  + '</a>');
            var selectize =selectizeUsers[0].selectize;
            var values = selectize.items;
            //getUsersData();
       },
          load: function(query, callback) {
           if (!query.length) return callback();
           $.ajax({
               url: base_url  + 'ajax/search-groups',
               type: 'GET',
               dataType: 'json',
               data: {
                   search: query
               },
               error: function() {
                   callback();
               },
               success: function(res) {
                   callback(res.data);
               }
           });
       }
   });

    var selectizePages = $('#pageTag').selectize({
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        maxItems: 1,
        plugins: ['remove_button'],
        render: {
            option: function(item, escape) {
                if(item.about != null)
                {
                    var about = escape(item.about);
                }
                else
                {
                    var about = '(no description added)';
                }

                return '<div class="media big-search-dropdown">' +
                    '<a class="media-left" href="#">' +
                        '<img src="'+ item.avatar_link + '" alt="...">' +
                    '</a>' +
                '<div class="media-body">' +
                    '<h4 class="media-heading">' + escape(item.name) + '</h4>' +
                    '<p>' +  about +  '</p>' +               '</div>' +
                '</div>';
            },

       },
       onChange: function(value)
       {
            $('[name="page"]').val(value);
            // $('.user-tags-added').find('.user-tag-names').append('<a href="#">' + value  + '</a>');
            var selectize =selectizeUsers[0].selectize;
            var values = selectize.items;
            //getUsersData();
       },
          load: function(query, callback) {
           if (!query.length) return callback();
           $.ajax({
               url: base_url  + 'ajax/search-pages',
               type: 'GET',
               dataType: 'json',
               data: {
                   search: query
               },
               error: function() {
                   callback();
               },
               success: function(res) {
                   callback(res.data);
               }
           });
       }
   });
}
function textAreaAdjust(o) {
  o.style.height = "1px";
  o.style.height = (1+o.scrollHeight)+"px";
}

//E2G Mark - click action for subscription managemet link for stripe account
$(document).on('click','.subscription-management',function(e){
  e.preventDefault();
  $.ajax({
    url: SP_source() + '/ajax/subscription-management',
    type: 'GET',
    error: function(xhr, textStatus, errorThrown) {
      alert("HTTP Status Code: " + xhr.status + "\n" + xhr.responseJSON.message);
    },
    success: function(res) {
      window.location.href = res.portal_url;
      //  window.open(res.portal_url, '_blank');
    }
  });
});
