$(document).ready(function() {
    $('.box-slider-frame').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: "<div class='box-slider-arrow prev slick-prev'></div>",
        nextArrow: "<div class='box-slider-arrow next slick-next'></div>",
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });
    $('.sliding-card').matchHeight();
    $('.inner-wrap-box').matchHeight();



    $(".modal").on('hidden.bs.modal', function () {
        $("#nofocusvideo").attr('src','');
    });

    $(".loadvideo").click(function () {
        $("#nofocusvideo").attr('src', $(this).data('content'));
        $("#videoModalLabel").html($(this).data('title'));
    });

    $("#edit-page-about").click(function () {
        $("#page_about").prop('readonly', false);
        $("#save-div").show();
        $("#edit-div").hide();
    });

    $("#cancel").click(function () {
        $("#page_about").val($("#old_page_about").val());
        $("#page_about").prop('readonly', true);
        $("#save-div").hide();
        $("#edit-div").show();
    });

    $("#save").click(function () {
        $.ajax({
            url: SP_source() + 'page-about',
            type: 'post',
            data: $("#page-about-from").serialize(),
            success: function(responseText) {
               $("#page_about").val(responseText.about);
               $("#page_about").prop('readonly', true);
               $("#save-div").hide();
               $("#edit-div").show();
            }
        });

     });

    $("#edit-details").click(function () {
        $(".page-details").prop('readonly', false);
        $("#save-details-div").show();
        $("#edit-details-div").hide();
    });

    $("#cancel-details").click(function () {
        $("#page_phone").val($("#old_page_phone").val());
        $("#page_email").val($("#old_page_email").val());
        $("#page_website").val($("#old_page_website").val());
        $("#page_address").val($("#old_page_address").val());
        $(".page-details").prop('readonly', true);
        $("#save-details-div").hide();
        $("#edit-details-div").show();
    });

    $("#save-details").click(function () {

        $.ajax({
            url: SP_source() + 'page-contact-info',
            type: 'post',
            data: $("#save-details-form").serialize(),
            success: function(responseText) {
               $("#page_phone").val(responseText.phone);
               $("#page_email").val(responseText.email);
               $("#page_website").val(responseText.website);
               $("#page_address").val(responseText.address);
               $(".page-details").prop('readonly', true);
               $("#save-details-div").hide();
               $("#edit-details-div").show();
            }
        });

    });

    $('.page-services, .page-gallery-index, .all-albums, .all-photos, .page-video-index').jscroll({ // load more business-services, page gallery photos
      nextSelector: 'a.jscroll-next:last',
      callback: initiateFunctions(),
    });

    function initiateFunctions(){
        selectedImagesDelete();
        selectedVideoDelete();
    }

    var photos_to_delete = [];
    function selectedImagesDelete(){
        $(".page-album-checkbox").change(function() {

            if(this.checked) {
                if( !(photos_to_delete.includes($(this).val())) ){
                    photos_to_delete.push($(this).val());
                }
            }
            else{
                var index = photos_to_delete.indexOf($(this).val());
                if (index > -1) {
                    photos_to_delete.splice(index, 1);
                }
            }
            if(photos_to_delete.length > 0){
                $('#selected-delete').show();
            }
            else{
                $('#selected-delete').hide();
            }
        });


    }

    selectedImagesDelete();

    $("#selected-delete").on('click', function() {
        var album_id = $('#album_id').val();
        $.ajax({
            url: SP_source() + $('#username').val() +'/selected-delete',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: { photos_to_delete : photos_to_delete},
            success: function(responseText) {
                if(responseText.status){
                    var deleted_photos = responseText.photo_ids;
                    deleted_photos.forEach(function (photo_id) {
                        $("#photo_box_" + photo_id).hide();
                    });
                    $('#selected-delete').hide();
                }
                alert(responseText.message);
            }
        });
    });

    $('input[type=radio][name=selected_method]').change(function() {
        if($(this).val() == "new_album"){
            $('#new_album').show();
            $('#existing_album').hide();
            $('#add_photo_type').val('new_album');
        }
        else{
            $('#new_album').hide();
            $('#existing_album').show();
            $('#add_photo_type').val('existing_album');
        }
    });

    $('#order').on('change', function() {
        $("#order_form").submit();
    });

    var videos_to_delete = [];
    function selectedVideoDelete(){
        $(".page-video-checkbox").change(function() {
            if(this.checked) {
                if( !(videos_to_delete.includes($(this).val())) ){
                    videos_to_delete.push($(this).val());
                }
            }
            else{
                var index = videos_to_delete.indexOf($(this).val());
                if (index > -1) {
                    videos_to_delete.splice(index, 1);
                }
            }
            if(videos_to_delete.length > 0){
                $('#selected-video-delete').show();
            }
            else{
                $('#selected-video-delete').hide();
            }
        });
    }
    selectedVideoDelete();

    $("#selected-video-delete").on('click', function() {
        $.ajax({
            url: SP_source() + $('#username').val() + '/selected-video-delete',
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: { videos_to_delete : videos_to_delete, timeline_id : $('#timeline_id').val()},
            success: function(responseText) {
                if(responseText.status == "success"){
                    var deleted_videos = responseText.data.video_ids;
                    deleted_videos.forEach(function (video_id) {
                        $("#video_box_" + video_id).hide();
                    });
                    $('#selected-video-delete').hide();
                }
               alert(responseText.data.message);
            }
        });
    });

    //$('#interestModal').modal('show');

    // $('#interestModal').on('hide.bs.modal', function(e) {
    //    e.preventDefault();
    // });

    $('#saveinterest').on('click', function(e) {
        e.preventDefault();
        data = $('#saveinterest_form').serialize();
        $.ajax({
            url: SP_source() + 'saveinterest',
            type: 'POST',
            data: data,
            success: function(responseText) {
                if (responseText.status == 200) {
                    window.location = responseText.redirect_url;
                }
                else {
                    $('.login-errors').html("<div class='login-error-text'>" + responseText.message + "</div>");
                }
            }
        });
    });

    $(".checkall").on("click", function() {
        $(".check_"+$(this).data('parentid')).prop('checked',true);
    });

    $(".uncheckall").on("click", function() {
        $(".check_"+$(this).data('parentid')).prop('checked',false);
    });


    $(window).load(function(){
        if( $('.suggested_friends--cntnr').length > 0 ){

            $.ajax({
                url: SP_source() + 'ajax/suggested-friends-list',
                type: 'get',
                success: function(responseText) {
                    if (responseText.status == 200) {
                        $('.suggested_friends--cntnr').empty().html(responseText.data.original);
                    }
                }
            });
        }

        if( $('.suggested_pages--cntnr').length > 0 ){

            $.ajax({
                url: SP_source() + 'ajax/suggested-pages-list',
                type: 'get',
                success: function(responseText) {
                    if (responseText.status == 200) {
                        $('.suggested_pages--cntnr').empty().html(responseText.data.original);
                    }
                }
            });
        }

        if( $('.suggested_groups--cntnr').length > 0 ){

            $.ajax({
                url: SP_source() + 'ajax/suggested-groups-list',
                type: 'get',
                success: function(responseText) {
                    if (responseText.status == 200) {
                        $('.suggested_groups--cntnr').empty().html(responseText.data.original);
                    }
                }
            });
        }

        if( $('.trending_tags--cntnr').length > 0 ){

            $.ajax({
                url: SP_source() + 'ajax/trending-tags-list',
                type: 'get',
                success: function(responseText) {
                    if (responseText.status == 200) {
                        $('.trending_tags--cntnr').empty().html(responseText.data.original);
                    }
                }
            });
        }

        if( $('.trending_interests--cntnr').length > 0 ){

            $.ajax({
                url: SP_source() + 'ajax/trending-interests-list',
                type: 'get',
                success: function(responseText) {
                    if (responseText.status == 200) {
                        $('.trending_interests--cntnr').empty().html(responseText.data.original);
                    }
                }
            });
        }

        $("#business_adcenter_create").show();
    })

    jQuery('#social-icon-size, #social-icon-frame, #social-icon-color').on('change', function () {
        var imageVar = jQuery('#social-icon-size').val() + jQuery('#social-icon-frame').val() + jQuery('#social-icon-color').val();
        var imageURL = "https://yudusocial.com/social-icons/yudu-" + imageVar + ".svg";
        var htmlCode = "";

        if(jQuery('#customURL').is(':checked')){
            htmlCode = "<a href='" + jQuery('#social-icon-url').val() + "' target='_blank'><img src='" + imageURL + "' alt='YUDU' /></a>";
        } else {
            htmlCode = "<a href='https://yudusocial.com/' target='_blank'><img src='" + imageURL + "' alt='YUDU' /></a>";
        }

        jQuery('#social-icon-preview').prop('src', imageURL);
        jQuery('#social-icon #icon-preview').prop('class', jQuery('#social-icon-color').val());
        jQuery('textarea#social-icon-html-code').val(htmlCode);
    });

    jQuery('#social-icon-size').trigger('change');

    jQuery('#customURL').on('change', function(){
        if (jQuery(this).is(':checked')) {
            jQuery('#yudu-brand #social-icon .fields .field-col.field-col-two').fadeIn(200);
            jQuery('#social-icon-url').val('https://yudusocial.com/');
        } else {
            jQuery('#yudu-brand #social-icon .fields .field-col.field-col-two').fadeOut(200);
            jQuery('#social-icon-url').val('https://yudusocial.com/');
        }
    });

    jQuery("#social-icon-url").keydown(function(e) {
        var oldvalue=jQuery(this).val();
        var field=this;
        setTimeout(function () {
            if(field.value.indexOf('https://yudusocial.com/') !== 0) {
                jQuery(field).val(oldvalue);
            }
        }, 1);

        jQuery('#social-icon-size').trigger('change');
    });

    jQuery("#social-icon-url").bind('paste, blur', function() {
        console.log('yay');
        jQuery('#social-icon-size').trigger('change');
    });

    jQuery('#copy-code').on('click', function(){
        jQuery("textarea").select();
        document.execCommand('copy');

        jQuery(this).text('Copied!');
        setTimeout(() => {
          jQuery(this).text('Copy to Cliboard');
        }, 2000);
    });

//--------------- AdCenter first step

    if($(".ad-center-card").length > 1) {
        $(".ad-center-card").matchHeight();
    }

    $('#adcenterCreateModal').modalWizard().on('submit', function (e) {

        var current_step = $("#adcenterCreateModal").attr('data-current-step');

        if(current_step != 5) return false;

        if( $("#payment_status").val() ){
            $(this).unbind("submit");

        }else {
            e.preventDefault();

            var payment = $("#total_payment").val();

            if( $("#card-errors").text() == "" && $("#cardholder-name").val() != "" && $(".saved_card").is(":checked") == false && payment > 0) {

                $.alert({
                    title: 'Error!',
                    content: 'Please click SAVE CARD to store your Credit Card details before submitting Ad for review.',
                });
                return false;
            }

            if( $(".saved_card").length == 0 && payment > 0) {

                $.alert({
                    title: 'Error!',
                    content: 'Please enter your Credit Card details before submitting Ad for review.',
                });
                return false;
            }

            if( $(".saved_card").length > 0 &&  $(".saved_card").is(":checked") == false && payment > 0) {

                $.alert({
                    title: 'Error!',
                    content: 'Please click SAVE CARD to store your Credit Card details or select a card already saved before submitting Ad for review.',
                });
                return false;
            }
            else if( $(".saved_card").is(":checked") == false && payment > 0) {

                $.alert({
                    title: 'Error!',
                    content: 'Please click SAVE CARD to store your Credit Card details before submitting Ad for review.',
                });
                return false;
            }

            var ele = $('#adcenterCreateModal');

            var confirm_message = "Would you like to submit this Ad?";
            if( payment > 0 ){
                confirm_message = "Would you like to submit this Ad with "+"$"+payment+" Payment?"
            }

            var confirm = window.confirm(confirm_message);
            if( confirm ){

                //$("#adcenter-submit").button('loading');

                /*$('#adcenter_form').ajaxForm({
                    url: SP_source() + 'ajax/validate-card-payment-new',
                    dataType: 'json',

                    beforeSend: function() {
                        $("#adcenter-submit").button('loading');
                        $(".model_footer").find("#adcenter-submit").attr("disabled", true);
                    },
                    success: function(response) {
                        if(response.status == 500){
                            var err_message = '<ul>'
                            err_message += '<li> An error occurred. Please try after sometime. </li>'

                            if(response.message) {
                                err_message += '<li>'+ response.message +' </li>'
                            }

                            err_message += '</ul>';
                            $.alert({
                                title: 'Error!',
                                content: err_message,
                            });

                            $("#adcenter-submit").button('reset');
                            $(".model_footer").find("#adcenter-submit").attr("disabled", false);

                            if(response.ad_id) {
                                $("#ad_id").val(response.ad_id);
                            }
                        }
                        else{

                            if(response.payment_status.status == true) {

                                $("#payment_status").val(response.payment_status.status);
                                $("#ad_id").val(response.ad_id);

                                $.alert({
                                    title: 'Info',
                                    content: 'Your advertisement has been submitted for review and is usually approved within 24 hours. If your advertisement is not approved you will receive an email from us with the reason why.'
                                });

                                $(".model_footer").find("a.prev").hide();

                                location.reload();

                                $('#adcenter_form').clearForm();
                            }else{
                                $(".card_save_info").text(response.payment_status.message);
                            }
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        if(jqXHR.status != 200){
                            location.reload();
                        }

                        $.alert({
                            title: 'Error!',
                            content: 'An unexpected error occurred. Please try after sometime'
                        });

                        $("#adcenter-submit").button('reset');
                        $(".model_footer").find("#adcenter-submit").attr("disabled", false);
                    }
                });*/

                $("#adcenter-submit").button('loading');
                $(".model_footer").find("#adcenter-submit").attr("disabled", true);
                $(".back-btn").hide();
                //var card_id = $(".saved_card:checked").data('card-id');
                //var page_id = $("#page_id").val();
                $.ajax({
                    url: SP_source() + 'ajax/validate-card-payment',
                    type: 'POST',
                    dataType: 'json',
                    data: $("#adcenter_form").serialize(),
                    success: function(response) {

                        if(response.status == 500){
                            var err_message = '<ul>'
                            err_message += '<li> An error occurred. Please try after sometime. </li>'

                            if(response.message) {
                                err_message += '<li>'+ response.message +' </li>'
                            }

                            err_message += '</ul>';
                            $.alert({
                                title: 'Error!',
                                content: err_message,
                            });

                            $(".model_footer").find("#adcenter-submit").attr("disabled", false);
                            $("#adcenter-submit").button('reset');
                            if(response.ad_id) {
                                $("#ad_id").val(response.ad_id);
                            }
                        }
                        else{

                            if(response.payment_status.status == true) {

                                $("#payment_status").val(response.payment_status.status);
                                $("#ad_id").val(response.ad_id);

                                $.alert({
                                    title: 'Info',
                                    content: 'Your advertisement has been submitted for review and is usually approved within 24 hours. If your advertisement is not approved you will receive an email from us with the reason why.'
                                });

                                //$("#adcenter-submit").button('reset');
                                $(".model_footer").find("a.prev").hide();
                                //$("#adcenter-submit").trigger("click");
                                $("#adcenter_form").submit();
                                $(".model_footer").find("#adcenter-submit").attr("disabled", true);

                                $('#adcenter_form').clearForm();
                            }else{
                                $(".card_save_info").text(response.payment_status.status);
                            }
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        if(jqXHR.status != 200){
                            location.reload();
                        }

                        $("#adcenter-submit").button('reset');
                        $(".model_footer").find("#adcenter-submit").attr("disabled", false);

                        $.alert({
                            title: 'Error!',
                            content: 'An unexpected error occurred. Please try after sometime'
                        });
                    }
                });

            }else{
                $(this).modal('show');
            }
        }
    });

    $(".closeadCeterModal").click(function() {
        var confirm = window.confirm("Would you like to close Ad Center without save?");
        if( confirm ){
            $('#adcenterCreateModal').trigger('reset');
            $(".photo_video_preview").remove();
            $("#ad_preview, .ad_post_preview").empty();
            $("#adcenterCreateModal").attr('data-current-step', 1);
            $("#adcenterCreateModal").find("[data-step=1]").show().siblings("[data-step]").hide().end().trigger("update", "#adcenterCreateModal");
            $('#adcenterCreateModal').modal('hide');
        }
    });

    $('#previewAdModel').on('show', function() {
        $('#adcenterCreateModal').css('opacity', .5);
        //$('#adcenterCreateModal').unbind();
    });

    $('#previewAdModel').on('hidden.bs.modal', function () {
        $('#adcenterCreateModal').css('opacity', 1);
        $("body").addClass("modal-open");
    });

    if($('#ad_center_news_feed_tab').length > 0)
        $('#ad_center_news_feed_tab').tab('show');


    $.validator.addMethod('validUrl', function(value, element) {
        var url = $.validator.methods.url.bind(this);
        return url(value, element) || url('http://' + value, element);
    }, 'Please enter a valid URL');

    $(".btn_continue").click(function() {
        $(".footer_error_message").html('');
        var current_step = $("#adcenterCreateModal").attr('data-current-step');

        var step = $("#adcenterCreateModal").find("[data-step="+current_step+"]");
        var stepIsValid = true;
        var form = $("#adcenter_form");
        var validator = form.validate({
            ignore:[],
            rules: {
                ad_title:{
                    required: true,
                    minlength: 2
                },

                ad_description:{
                    required: true,
                    minlength: 2
                },

                ad_link:{
                    required: true,
                    validUrl: true
                }
            },
            errorPlacement: function(error, element) {

                if(element.parent().hasClass('input-group')){
                    error.insertAfter( element.parent() );
                }else{
                    error.insertAfter( element );
                }
            }
        });

        if(current_step == 1) {

            var ad_title = $("#ad_title").val();
            var page_id = $("#page_id").val();
            //validate title already exists
            $.ajax({
                url: SP_source() + 'ajax/verify-adtitle',
                type: 'POST',
                dataType: 'json',
                async:false,
                data: {
                    title : ad_title,
                    page_id : page_id
                },
                success: function(response) {

                    if(response.status == true) {
                        $(".footer_error_message").html(response.message);
                        stepIsValid = false;
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    if(jqXHR.status != 200){
                        location.reload();
                    }

                    $.alert({
                        title: 'Error!',
                        content: 'An unexpected error occurred. Please try after sometime'
                    });
                }
            });

            $('input', step).each( function(index) {
                var xy = validator.element(this);
                stepIsValid = stepIsValid && (typeof xy == 'undefined' || xy);
            });

            if(!stepIsValid) {
                return false;
            }

            $("#preview-news").find("#ad_preview_title").text( $("#ad_title").val() );
            $("#preview-news").find("#ad_preview_description").text( $("#ad_description").val() );

            $(".ad_post_preview_title").text( $("#ad_title").val() );
            $(".ad_post_preview_description").text( $("#ad_description").val() );
            calculateAmount();
        }

        if(current_step == 2) {

            var stepIsValid = true;

            if( $(".interest_check:checked").length == 0 ){
                $(".footer_error_message").html("You must select at least one interest");
                return false;
            }else{
                var ad_duration = $("input[name='ad_duration']:checked").val()
                var selected_interest = [];
                $(".interest_check:checked").each(function(){
                    var interest_id = $(this).data('interest-id');
                    var interest_subid = $(this).val();
                    var interest_name = $("#collapse"+interest_id).data('interest-name');
                    var interest_subname = $(this).data('interest-subname');
                    var interest_subprice = ad_duration * $(this).data('interest-price');
                    selected_interest.push( {
                        interest_id: interest_id,
                        interest_name: interest_name,
                        interest_subid: interest_subid,
                        interest_subname: interest_subname,
                        interest_subprice:  interest_subprice
                   });
                });

                var interest_summery = {}; var i =0;

                selected_interest.forEach(function(value, key) {
                    if (!interest_summery[value['interest_name']]) {

                        interest_summery[value['interest_name']] = {}
                    }

                    interest_summery[value['interest_name']][i] = value;
                    i++;
                });

                var order_summery = "";
                var total = 0;

                order_summery += '<div class="row">';
                $.each( interest_summery, function( interest_key, interest_value ) {
                    var sub_total = 0;

                    $.each( interest_value, function( key, value ) {
                        price = ( value['interest_subprice'] == "" ) ? '0.00' : value['interest_subprice'];
                        sub_total = sub_total + parseFloat(price);
                    });

                    order_summery +=
                    '<div class="col-xs-12">'+
                        '<div class="table-responsive">' +
                            '<table class="table summary-table">' +
                                '<thead>' +
                                    '<tr>' +
                                        '<th class="summary-head">' +
                                            interest_key +
                                        '</th>' +
                                        '<th class="summary-head  text-right">' +
                                            "$" + dataRound(sub_total) +
                                        '</th>' +
                                    '</tr>' +
                                '</thead>' +
                                '<tbody>'
                                    $.each(interest_value, function (key, value) {
                                        price = (value['interest_subprice'] == "") ? '0.00' : value['interest_subprice'];
                                        total = total + parseFloat(price);

                                        order_summery += '<tr>' +
                                            '<td class="summary-data1">' +
                                            '&nbsp;' + value['interest_subname'] +
                                            '</td>' +
                                            '<td class="summary-data2">' +
                                            "$" + dataRound(price) +
                                            '</td>' +
                                        '</tr>'
                                    });
                            order_summery += '</tbody>' +
                            '</table>' +
                        '</div>' +
                    '</div>'
                });

                //$("#total_payment, #ad_amount").val(dataRound(total));
                //$(".total_price").text("$"+dataRound(total));
                $(".total_price").text( "$"+ $("#total_payment").val() );
                $("#ad_order_summery").html(order_summery);
            }
        }

        if(current_step == 3) {

            var media = $(".media_type:checked").data("value")

            if(media == "image") {
                if( $("#ad_photo_fileupload").val() == "" && $(".photo_video_preview").length == 0){

                    $(".footer_error_message").html("Please upload a photo");
                    return false;
                }
            }

            if(media == "video") {
                if( $("#ad_videoupload").val() == ""  && $(".photo_video_preview").length == 0 ){
                    $(".footer_error_message").html("Please upload a video");
                    return false;
                }
            }
        }

        if(current_step == 4) {
            $("#payment_method").show();
            if( $("#total_payment").val() <= 0 ) {
                $("#payment_method").hide();
            }

            $(".secure-ssl").show();
        }
    });

    //Interest checkbox
    if( $(".interest_parent_check").length > 0 ){

        $(".interest_parent_check").click(function() {

            var interest_id = $(this).data('interest-id');
            if( $(this).is(":checked") ) {
                $("#collapse" + interest_id).find(".interest_check").prop("checked", true);
            }else{

                $("#collapse" + interest_id).find(".interest_check").prop("checked", false);
            }
            calculateAmount();
        });
    }

    $(".interest_check").click(function() {

        var interest_id = $(this).data('interest-id');
        var checked_length = $("#collapse" + interest_id).find(".interest_check:checked").length;

        $(this).parent().closest("#collapse"+interest_id).parent().find('.interest_parent_check').prop("checked", true);

        if( checked_length == 0) {
            $(this).parent().closest("#collapse"+interest_id).parent().find('.interest_parent_check').prop("checked", false);
        }

        calculateAmount();
    });

    function calculateAmount() {

        var ad_duration = $("input[name='ad_duration']:checked").val();
        var amount = 0;

        var total_user_count = $("#total_user_count").val();
        var price_per_user   = $("#price_per_user").val();

        $(".interest_check:checked").each(function(){

            interest_price = $(this).data('interest-price');
            if(typeof interest_price == 'undefined' || interest_price == "") {
                interest_price = 0;
            }

            amount = parseFloat(amount) + parseFloat(interest_price);
        });

        var base_price = total_user_count * parseFloat(price_per_user);

        var total_payment = base_price + (amount * ad_duration);

        if( $(".interest_check:checked").length > 0 ) {
            $(".ads_payment").text("$"+ dataRound(total_payment) );
            $(".base_price").text("$"+ dataRound(base_price));
            $("#total_payment, #ad_amount").val(dataRound(total_payment));
        }else{
            $(".ads_payment").text('$0.00');
        }
    }

    $('#ad_photo_fileupload').on('change', function(e) {
        $(".btn_continue").prop("disabled", false);
        $(".footer_error_message").html('');

        $.each(this.files, function (index, file) {

            var allowed_size = 3; //in MB;
            if(!validate_file_size(file, allowed_size)){
                $("ul#preview-thumbnails").empty();
                $(".footer_error_message").html("Please check file upload size (Maximum allowed size : "+ allowed_size +" MB)");
                $(".btn_continue").prop("disabled", true);
                $("#ad_photo_fileupload").val('');
                return false;
            }

            var allowed_extensions = [ "jpg", "jpeg", "png" ];
            if(!validate_file_extension(file, allowed_extensions)) {

                $("ul#preview-thumbnails").empty();
                $(".footer_error_message").html("Allowed file formats are : "+allowed_extensions.join(", ") );
                $(".btn_continue").prop("disabled", true);
                $("#ad_photo_fileupload").val('');
                return false;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                $("ul#preview-thumbnails").html(
                                            '<li class="photo_video_preview col-xs-6 col-sm-4 col-md-4">'+
                                                    '<div class="panel panel-default checkbox-panel">'+
                                                        '<div class="panel-body nopadding">'+
                                                            '<div class="widget-card adcenter_preview with-slim">'+
                                                                '<div class="widget-card-bg">'+
                                                                    '<img src='+reader.result+' class="set-fav-mh">'+
                                                                    '<a href="#" class="remove-thumb remove-thumb-ad-image adcenter-close"><i class="fa fa-times-circle" aria-hidden="true"></i></a>' +
                                                                '</div>'+
                                                            '</div>'+
                                                        '</div>'+
                                                    '</div>'+
                                            '</li>');
                $("#preview-news").find("#ad_preview").html('<img src='+reader.result+' class="set-fav-mh">');

                var image = new Image();
                image.src = reader.result;
                var ratio = 0;
                image.onload = function() {
                    width = image.width;
                    height = image.height;
                    ratio = (height / width) * 100;
                    if(ratio > 0)
                        $(".preview-ad-post-container").css("padding-bottom", ratio+'%');
                }

                if(ratio <= 0) {
                    $(".preview-ad-post-container").css("padding-bottom", '75%');
                }

                $(".ad_post_preview").html('<a href="javascript:;;"> <img src='+reader.result+' width="100%" height="100%"></a>');
            };
        });
    });

    // Removing ad-center selected image here
    $('body').on('click','.remove-thumb-ad-image',function(e){
        e.preventDefault();
        $("ul#preview-thumbnails").empty();
        $("#ad_photo_fileupload").val('');
    });


    $(".back-btn").click(function() {
        $(".secure-ssl").hide();
        $(".btn_continue").prop("disabled", false);
        $(".footer_error_message").html('');
    });

    $('#ad_videoupload').on('change', function(e) {

        $(".btn_continue").prop("disabled", false);
        $(".footer_error_message").html('');
        $.each(this.files, function (index, file) {

            var allowed_size = 10; //in MB;
            if(!validate_file_size(file, allowed_size)){
                $("ul#preview-thumbnails").empty();
                $(".footer_error_message").html("Please check file upload size (Maximum allowed size : "+ allowed_size +" MB)");
                $(".btn_continue").prop("disabled", true);
                $("#ad_videoupload").val('');
                return false;
            }

            var allowed_extensions = [ "mp4" ];
            if(!validate_file_extension(file, allowed_extensions)) {

                $("ul#preview-thumbnails").empty();
                $(".footer_error_message").html("Allowed file format : "+allowed_extensions.join(", ") );
                $(".btn_continue").prop("disabled", true);
                $("#ad_videoupload").val('');
                return false;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                $("ul#preview-thumbnails").html(
                                            '<li class="photo_video_preview col-xs-6 col-sm-4 col-md-4">'+
                                                    '<div class="panel panel-default checkbox-panel">'+
                                                        '<div class="panel-body nopadding">'+
                                                            '<div class="widget-card adcenter_preview with-slim">'+
                                                                '<div class="widget-card-bg">'+
                                                                    '<video width="100%" height="100%" class="embed-responsive-item video-frame" src='+reader.result+' type="video/mp4" class="set-fav-mh"></video>'+
                                                                    '<a href="#" class="remove-thumb remove-thumb-ad-video adcenter-close"><i class="fa fa-times-circle" aria-hidden="true"></i></a>' +
                                                                '</div>'+
                                                            '</div>'+
                                                        '</div>'+
                                                    '</div>'+
                                            '</li>');
                $(".preview-ad-post-container").css("padding-bottom", '75%');
                $("#preview-news").find("#ad_preview").html('<video width="100%" height="80%" class="embed-responsive-item video-frame" controls="true" src='+reader.result+' type="video/mp4" class="set-fav-mh" ></video>');
                $(".ad_post_preview").html('<a href="javascript:;;"> <video class="embed-responsive-item video-frame" controls="true" width="100%" height="100%" src='+reader.result+' type="video/mp4"></video> </a>');
            };
        });
    });

    // Removing ad-center selected video here
    $('body').on('click','.remove-thumb-ad-video',function(e){
        e.preventDefault();
        $("ul#preview-thumbnails").empty();
        $("#ad_videoupload").val('');
    });

    function validate_file_extension(file, allowed_extensions) {
        fileName = file.name;
        var file_extension = fileName.split('.').pop().toLowerCase();
        for(var i = 0; i <= allowed_extensions.length; i++) {
            if(allowed_extensions[i] == file_extension) {
                return true; // valid file extension
            }
        }

        return false;
    }

    function validate_file_size(file, size) {
        fileSize = file.size;

        if(fileSize <= 0) return false;

        var to_byte = size * (1024*1024); //to byte
        if(fileSize <= to_byte) {
            return true;
        }

        return false;
    }

    $(document).on('click', '.media_type',function(e) {

        var media = $(this).data('value');
        $(".media_type").removeClass('active');
        $(this).addClass('active');

        var title = $(".media_type.active").data('title');

        $(".selected_media_type").html(title);

        $("#image-container, #video-container").hide(); $(".photo_video_preview").remove();
        $("#ad_photo_fileupload, #ad_videoupload").val('');
        $("#ad_preview, .ad_post_preview").empty();

        $(".footer_error_message").html('');

        if( media == "image" ) {
            $("#image-container").show();
        }else{
            $("#video-container").show();
        }
    });

    $("#add_payment_method").click(function() {
        $(".card_save_info").empty();
        if( $(".saved_card").length > 0 ) {
            $(".saved_card").prop('checked', false);
        }

        $("#payment_method_container").slideToggle('slow', callbackFn);
        //$(".payment_method_toggle").removeClass('fa-plus-circle').addClass('fa-minus-circle');

        $(".payment_method_toggle").toggleClass('fa-plus-circle fa-minus-circle');

        if( !$("#payment_method_container").is(":visible") ) {

            if( $(".saved_card").length > 0 ) {
                $(".saved_card").eq(0).prop("checked", true);
            }
        }
    });

    function callbackFn(){
        var $container = $("#payment_method_container");
        if( !$(this).is(":visible") ) {

            if( $(".saved_card").length > 0 ) {
                $(".saved_card").eq(0).prop("checked", true);
            }
        }
    }

    //TODO: E2G Mark - Disable original Stripe Code for card-element
    /*    
    if( $(document, ".saved_card").length > 0 ) {
        $(document).on('click', '.saved_card', function(){
            $(".card_save_info").empty();
            if( $("#payment_method_container").is(":visible") ) {
                $("#payment_method_container").slideToggle('slow');
                if($(".payment_method_toggle").hasClass('fa-minus-circle')) {
                    $(".payment_method_toggle").removeClass('fa-minus-circle').addClass('fa-plus-circle');
                }
            }
        });
    }

    if( $("#card-element").length > 0 ) {

        var stripe = Stripe(stripe_key);

        var elements = stripe.elements();
        var cardElement = elements.create("card", {
                style: {
                  base: {
                    color: "#32325D",
                    fontWeight: 400,
                    fontFamily: "Open Sans, Segoe UI, sans-serif",
                    fontSize: "14px",
                    fontSmoothing: "antialiased",

                    "::placeholder": {
                      color: "#354052"
                    }
                  },
                  invalid: {
                    color: "#E4584C"
                  }
                }
              });

        cardElement.mount('#card-element');

        var cardholderName = document.getElementById('cardholder-name');
        var cardButton = document.getElementById('card-button');
        //var clientSecret = cardButton.dataset.secret;

        cardElement.addEventListener('change', function(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        cardButton.addEventListener('click', function(ev) {

            $(".card_save_info").html("Processing to save your card information. Please wait...");

            var clientSecret = $("#card-button").data("secret");
            stripe.confirmCardSetup(
                clientSecret,
                {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: cardholderName.value,
                        }
                    }
                }
            ).then(function(result) {
                if (result.error) {
                    $(".card_save_info").empty();
                    if( $.trim($("#cardholder-name").val()) == "" ){
                        //$("#payment_status_message").html("Please enter card Holder Name");
                        $.alert({
                            title: 'Error!',
                            content: 'Please Enter Credit Card Information.',
                        });

                    }else{
                        if($("#card-errors").text() == "")
                            $(".card_save_info").empty().html(result.error.message);
                        // Inform the user if there was an error.
                       // var errorElement = document.getElementById('card-errors');
                       // errorElement.textContent = result.error.message;
                    }

                    return false;
                } else {
                    $(".card_save_info").empty();
                    $("#card-button").prop("disabled", true); $("#card-button").attr("disabled", true);// disabled save card button

                    var page_id = $("#page_id").val();
                    var payment = $("#total_payment").val();
                    $("#card-button").button('loading'); //enable loading
                    $.ajax({
                        url: SP_source() + 'ajax/ad-center-save-card',
                        type: 'POST',
                        dataType: 'json',
                        data: {
                           token: result.setupIntent.payment_method,
                           stoken: result.setupIntent.id,
                           page_id: page_id,
                           amount: payment,
                           name: cardholderName.value
                        },
                        success: function(response) {

                            if(response.status == true) {

                                $("#cardholder-name").val('');

                                $.alert({
                                    title: 'Info',
                                    content: response.message,
                                });
                                $(".card_save_info").empty();
                                cardElement.clear();

                                //$(".customer_token").val(response.cus_token);
                                //$("#payment_status_message").html(response.message);

                                $("#payment_status").val('');
                                $("#card-button").prop("disabled", false); // reset save card button
                                $("#payment_method_container").slideToggle('slow');
                                $(".payment_method_toggle").toggleClass('fa-minus-circle fa-plus-circle');

                                var card_html = '<tr>'+
                                                    '<td width="30px">'+
                                                        '<label class="yd-custom-radio">'+
                                                        '<input type="radio" name="saved_card" class="saved_card" value="'+response.card_details.card_id+'" data-card-id="'+response.card_details.card_id+'" checked="true">'+
                                                        '<span class="checkmark"></span></label>'+
                                                    '<td style="font-weight:600 !important;">'+
                                                        '<img src="/themes/default/assets/images/payment/'+response.card_details.card_brand+'.png" alt="" style="width: 35px;"> '+
                                                            response.card_details.card_brand +' *'+response.card_details.card_no+
                                                    '</td>'+

                                                    '<td class="total_price text-right">$'+payment+'</td>'+
                                                '</tr>'
                                $("#payment_method .saved_cards_list").append(card_html);

                                $("#card-button").prop("data-secret", response.cs);
                                $("#card-button").data("secret", response.cs);
                            }else{
                                $("#card_save_info").text(response.message);
                            }
                        },
                        error: function(jqXHR, textStatus, errorThrown) {
                            if(jqXHR.status != 200){
                                location.reload();
                            }
                            $("#card-button").prop("disabled", false); // reset save card button
                            $(".card_save_info").empty();

                            $.alert({
                                title: 'Error!',
                                content: 'An unexpected error occurred. Please try after sometime'
                            });
                        },
                        complete: function() {
                            $("#card-button").button('reset'); // reset loading button
                        }
                   });
                  // The setup has succeeded. Display a success message.
                }
            });
        });
    }
    */
    // Like/Unlike the ads by user
    $(document).on('click','.like-ad',function(e) {

        e.preventDefault();

        adId = $(this).data('ad-id');
        like_btn = $(this);
        $.post(SP_source() + 'ajax/like-ads', {
            ad_id: adId
        }, function(data) {

            if (data.status == 200) {

                if (data.liked == true) {

                    $('.adlike-' + adId).parent().addClass('hidden');
                    $('.adunlike-' + adId).parent().removeClass('hidden');
                    $('.like_count-' + adId).html(data.likecount);

                }else {

                    $('.adlike-' + adId).parent().removeClass('hidden');
                    $('.adunlike-' + adId).parent().addClass('hidden');
                    $('.like_count-' + adId).html(data.likecount);
                }
            }
        });
    });

    $(document).on('change', '#summery_duration',function(e) {

        var duration = $(this).val();
        var page_id = $("#page_id").val();

        $.post(SP_source() + 'ajax/ads-summery', {
            duration: duration,
            page_id : page_id
        }, function(data) {

            if (data.status == 200) {
                $(".ads_summery_amount").empty().html(data.ads_amount);
                $(".ads_summery_count").empty().html(data.ads_count);
                $(".ads_summery_duration").empty().html(duration);
                $(".ads_summery_view").empty().html(data.views);
                $(".ads_summery_engagement").empty().html(data.engagements);
                $(".ads_summery_click").empty().html(data.clicks);
            }
        });
    });

    $(document).on('click', '.summery_duration_toggle',function(e) {

        var duration = $(this).data('duration');
        var page_id = $("#page_id").val();

        $.post(SP_source() + 'ajax/ads-summery', {
            duration: duration,
            page_id : page_id
        }, function(data) {

            if (data.status == 200) {
                $(".ads_summery_amount").empty().html(data.ads_amount);
                $(".ads_summery_count").empty().html(data.ads_count);
                $(".ads_summery_duration").empty().html(duration);

                $(".selected_duration").html(duration);
                $(".advt-summary-block.reached > .advt-summary-block-count").html(data.views);
                $(".advt-summary-block.post > .advt-summary-block-count").html(data.engagements);
                $(".advt-summary-block.link > .advt-summary-block-count").html(data.clicks);
            }
        });
    });

    //Select Audiance toggle icon
    $(".interests.collapse.in").each(function(){
            $(this).siblings(".panel-heading").find(".arrow > .panel-icon").addClass("fa fa-angle-right");
        });

        // Toggle plus minus icon on show hide of collapse element
        $(".interests.collapse").on('show.bs.collapse', function(){
            $(this).parent().find(".arrow > .panel-icon ").removeClass("fa-angle-right").addClass("fa-angle-down");
        }).on('hide.bs.collapse', function(){
            $(this).parent().find(".arrow > .panel-icon ").addClass("fa-angle-right").removeClass("fa-angle-down");
        });


    //Validate promo code
    $(".btn_promo").click(function() {

        var ad_promo = $("#ad_promo").val();
        var page_id = $("#page_id").val();
        var ad_duration = $("input[name='ad_duration']:checked").val();

        if( $.trim( ad_promo ) == "" ){
            $(".promo_status_message").addClass("error").html("Please enter promo code.");
            return false;
        }

        $.ajax({
            url: SP_source() + 'ajax/validate-adpromo',
            type: 'POST',
            dataType: 'json',
            data: {
                promo : ad_promo,
                page_id : page_id,
                duration : ad_duration
            },
            success: function(response) {

                if(response.status == true) {
                    $(".promo_status_message").removeClass("error").addClass("success").html(response.message);

                    $("#payment_method, .secure-ssl").hide();
                    $(".btn_promo").hide(); $(".btn_promo_remove").show();
                    $("#total_payment").val(0);

                }else {
                    $(".promo_status_message").removeClass("success").addClass("error").html(response.message);
                    $("#ad_promo").val('');
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                if(jqXHR.status != 200){
                    location.reload();
                }

                $.alert({
                    title: 'Error!',
                    content: 'An unexpected error occurred. Please try after sometime'
                });
            }
        });
    });

    $(".btn_promo_remove").click(function() {

        $("#payment_method, .secure-ssl").show();
        $(".promo_status_message").empty();
        $(this).hide(); $("#ad_promo").val('');
        $(".btn_promo").show();
        $("#total_payment").val( $("#ad_amount").val() );
    });

    //Business Page Ad Center Pause
    $(".adCenterPause").click(function(e) {
        e.preventDefault();

        var adcenter_id = $(this).data('adcenter-id');
        $.confirm({
            title: 'Confirm!',
            content: 'Are you sure you want Resume this Advt.?',
            confirmButton: 'Yes',
            cancelButton: 'No',
            confirmButtonClass: 'btn-primary orange-color',
            cancelButtonClass: 'btn-danger grey-color',

            confirm: function(){
                $.ajax({
                    url: SP_source() + 'ajax/adcenter-active/' + adcenter_id,
                    type: 'PUT',
                    dataType: 'json',
                    success: function(response) {
                        if (response.status == 200) {
                            location.reload();
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        if(jqXHR.status != 200){
                            location.reload();
                        }

                        $.alert({
                            title: 'Error!',
                            content: 'An unexpected error occurred. Please try after sometime'
                        });
                    }
                });
            },
            cancel: function(){

            }
        });
    });

    //Business Page Ad Center Resume
    $(".adCenterResume").click(function(e) {
        e.preventDefault();

        var adcenter_id = $(this).data('adcenter-id');
        $.confirm({
            title: 'Confirm!',
            content: 'Are you sure you want Pause this Advt.?',
            confirmButton: 'Yes',
            cancelButton: 'No',
            confirmButtonClass: 'btn-primary orange-color',
            cancelButtonClass: 'btn-danger grey-color',

            confirm: function(){
                $.ajax({
                    url: SP_source() + 'ajax/adcenter-pause/' + adcenter_id,
                    type: 'PUT',
                    dataType: 'json',
                    success: function(response) {
                        if (response.status == 200) {
                            location.reload();
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        if(jqXHR.status != 200){
                            location.reload();
                        }

                        $.alert({
                            title: 'Error!',
                            content: 'An unexpected error occurred. Please try after sometime'
                        });
                    }
                });
            },
            cancel: function(){

            }
        });
    });

});

function dataRound(value) {
    return (Math.round(value*100)/100).toFixed(2);
}
