
$(function () {

    $('.login-form').ajaxForm({
        url: SP_source() + 'login',
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content') },

        beforeSend: function () {
            login_form = $('.login-form');
            login_button = login_form.find('.btn-submit');
            login_button.attr('disabled', true);
            $('.login-progress').removeClass('hidden');
            $('.login-errors').html('');
        },

        success: function (responseText) {
            login_button.attr('disabled', false);
            $('.login-progress').addClass('hidden');
            if (responseText.status == 200) {
                window.location = responseText.redirect_url;
            } else {
                if (responseText.redirect_url) {
                    window.location.href = responseText.redirect_url;
                } else {
                    $('.login-errors').html("<div class='login-error-text'>" + responseText.message + "</div>");
                }
            }

        }
    });



    $('.signup-form').ajaxForm({
        url: SP_source() + 'register',
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf_token"]').attr('content')},

        beforeSend: function () {
            signup_form = $('.signup-form');
            signup_button = signup_form.find('.btn-submit');
            // signup_button.attr('disabled', true).append(' <i class="fa fa-spinner fa-pulse "></i>');
        },

        success: function (responseText) {
            // console.log(jQuery.parseJSON(responseText));
            signup_button.attr('disabled', false).find('.fa-spinner').addClass('hidden');
            if (responseText.status == 200) {
                if (responseText.emailnotify == "on") {
                    window.location = SP_source() + 'login?echk=' + responseText.emailnotify;
                }
                else {
                    window.location = responseText.redirect_url;
                }

            } else {
                $('.signup-errors').html('<ul class="login-error-text list-unstyled"></ul>');
                $.each(responseText.err_result, function (key, value) {
                    $('.signup-errors ul').append('<li>' + value[0] + '</li>');
                });
                window.scrollTo(0, 200);
            }

        }
    });


});